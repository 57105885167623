package it.neckar.react.common.button

import kotlin.contracts.contract

/**
 * The location of a button
 */
enum class IconLocation {
  Left,
  Center,
  Right,
}

/**
 * Represents a button icon with icon location
 */
sealed interface ButtonIcon {
  val icon: String
  val iconLocation: IconLocation

  companion object {
    fun centered(icon: String): ButtonIconCentered = ButtonIconCentered(icon)

    /**
     * Creates an icon on the left
     */
    fun left(icon: String): ButtonIconLeft = ButtonIconLeft(icon)

    /**
     * Creates an icon on the right
     */
    fun right(icon: String): ButtonIconRight = ButtonIconRight(icon)
  }
}


/**
 * Represents the icon for a button
 */
data class ButtonIconLeft(
  override val icon: String,
) : ButtonIcon {
  override val iconLocation: IconLocation
    get() = IconLocation.Left
}

data class ButtonIconRight(
  override val icon: String,
) : ButtonIcon {
  override val iconLocation: IconLocation
    get() = IconLocation.Right
}

data class ButtonIconCentered(
  override val icon: String,
) : ButtonIcon {
  override val iconLocation: IconLocation
    get() = IconLocation.Center
}


/**
 * Returns true if there is an icon on the left side of the button.
 *
 * Return false if this is null or the icon is on the right side.
 */
fun ButtonIcon?.isLeft(): Boolean {
  contract {
    returns(true) implies (this@isLeft != null)
  }

  if (this == null) {
    return false
  }

  return this.iconLocation == IconLocation.Left
}

/**
 * Returns true if there is an icon in the center of the button (without a label).
 *
 */
fun ButtonIcon?.isCenter(): Boolean {
  contract {
    returns(true) implies (this@isCenter != null)
  }

  if (this == null) {
    return false
  }

  return this.iconLocation == IconLocation.Center
}

/**
 * Returns true if there is an icon on the right side of the button.
 */
fun ButtonIcon?.isRight(): Boolean {
  contract {
    returns(true) implies (this@isRight != null)
  }

  if (this == null) {
    return false
  }

  return this.iconLocation == IconLocation.Right
}
