@file:Suppress("RUNTIME_ANNOTATION_NOT_SUPPORTED")

package it.neckar.react.common

import it.neckar.common.featureflags.FeatureFlag
import it.neckar.common.featureflags.FeatureFlagsSupport
import it.neckar.open.annotations.NonBlocking
import it.neckar.react.common.toast.*
import kotlinx.coroutines.*

/**
 * Launches an action. Returns immediately.
 *
 * Helper methods that does not return anything.
 *
 * * Use [deferAndNotify] if the return type shall be used.
 * * Use [executeAndNotify] if called from a suspend function.
 *
 * !!! Do *not* call from a suspend function.
 */
@NonBlocking
fun <T> launchAndNotify(successMessage: String? = null, action: suspend () -> T) {
  AppScope.launch {
    executeAndNotify(successMessage, action)
  }
}

/**
 * Executes a REST action.
 * Returns immediately.
 *
 * ATTENTION: Use the return type!
 *
 * * Use [launchAndNotify] if the return type shall not be used.
 * * Use [executeAndNotify] if called from a suspend function.
 *
 * !!! Do *not* call from a suspend function.
 *
 *
 * Avoid using this method if possible. This method should only be used, to combine `suspend` functions with old code.
 * Instead, create and execute a suspend method.
 */
@NonBlocking
fun <T> deferAndNotify(successMessage: String? = null, action: suspend () -> T): Deferred<T> {
  return AppScope.async {
    executeAndNotify(successMessage, action)
  }
}

/**
 * Executes an action and shows the success message if provided.
 *
 * Shows a success method on success.
 */
suspend fun <T> executeAndNotify(successMessage: String?, action: suspend () -> T): T {
  delayIfSlowUiEnabled()
  val result = action()
  if (successMessage != null) {
    notifySuccess(successMessage)
  }

  return result
}

/**
 * This method delays the UI if the corresponding feature flag is enabled
 * Blocks for 1000 milliseconds if the corresponding feature flag ([FeatureFlag.slowUi]) is set
 */
suspend fun delayIfSlowUiEnabled() {
  if (FeatureFlagsSupport.flags.contains(FeatureFlag.slowUi)) {
    delay(1000)
  }
}
