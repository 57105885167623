package components.schnellbestellung

import it.neckar.open.collections.fastForEachIndexed
import it.neckar.react.common.*
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.id
import react.*
import react.dom.*
import styled.*

fun RBuilder.carousel(name: String, images: List<String>, imageHeight: LinearDimension = 400.px) {

  div(classes = "col-12 carousel slide my-3") {
    attrs {
      id = "${name}CarouselIndicators"
    }
    attrs["data-bs-ride"] = "carousel"

    div(classes = "carousel-indicators mb-0") {
      images.fastForEachIndexed { index, _ ->
        button(type = ButtonType.button) {
          attrs["data-bs-target"] = "#${name}CarouselIndicators"
          attrs["data-bs-slide-to"] = "$index"
          addClassIf("active") { index == 0 }
        }
      }
    }

    div(classes = "carousel-inner mb-5 text-center") {
      images.fastForEachIndexed { index, imagePath ->
        div(classes = "carousel-item") {
          addClassIf("active") { index == 0 }
          carouselImage(imagePath, imageHeight)
        }
      }
    }

    button(classes = "carousel-control-prev", type = ButtonType.button) {
      attrs["data-bs-target"] = "#${name}CarouselIndicators"
      attrs["data-bs-slide"] = "prev"
      span(classes = "carousel-control-prev-icon") {
        attrs["aria-hidden"] = true
      }
      span(classes = "visually-hidden") {
        +"Previous"
      }
    }
    button(classes = "carousel-control-next", type = ButtonType.button) {
      attrs["data-bs-target"] = "#${name}CarouselIndicators"
      attrs["data-bs-slide"] = "next"
      span(classes = "carousel-control-next-icon") {
        attrs["aria-hidden"] = true
      }
      span(classes = "visually-hidden") {
        +"Next"
      }
    }
  }
}

fun RBuilder.carouselImage(imageSource: String, imageHeight: LinearDimension) {
  styledImg(src = imageSource) {
    attrs {
      classes = setOf("img-fluid rounded")
      alt = "..."
    }
    css {
      maxHeight = imageHeight
    }
  }
}
