package services

import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import it.neckar.lizergy.model.serializers.wunschsolarModel
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import services.http.WunschsolarClientService

/**
 * Contains the services that are used in the UI
 */

/**
 * HTTP client without authorization.
 */
val httpClientNoAuth: HttpClient = HttpClient {
  install(ContentNegotiation) {
    json(Json {
      serializersModule = SerializersModule {
        wunschsolarModel()
      }
    })
  }
}

/**
 * Storage service
 */
val wunschsolarService: WunschsolarClientService = WunschsolarClientService(httpClientNoAuth)
