package de.wunschsolar.model

import kotlinx.serialization.Serializable

/**
 * Represents a wunschsolar.de feedback
 */
@Serializable
data class Feedback(
  override val id: Message.MessageId = Message.MessageId.random(),

  val emoji: EmojiFeedback?,
  val message: String,

  val email: String?,
  val phone: String?,

  override val seriousness: Seriousness,

  ) : Message {

  override fun html(): String {
    //language=HTML
    return """
    <h3>Feedback</h3>

    <div style='float:left; width:30%'>
      <p>
        ${email?.let { "Email:<br>" }.orEmpty()}
        ${phone?.let { "Telefon:" }.orEmpty()}
      </p>
    </div>

    <div style='float:left; width:60%; margin-left:10%'>
      <p>
        ${email?.let { "<a href = mailto:$it>$it</a><br>" }.orEmpty()}
        ${phone?.let { "<a href = tel:+$it>+$it</a>" }.orEmpty()}
      </p>
    </div>

    <div style='float:left; width:100%;'>
      $message ${emoji?.toHTML().orEmpty()}
    </div>
    """.trimIndent()
  }

  enum class EmojiFeedback {
    None,
    Sad,
    SlightlySad,
    Neutral,
    SlightlyHappy,
    Happy,
  }
}

fun Feedback.EmojiFeedback.toMarkdown(): String {
  return when (this) {
    Feedback.EmojiFeedback.Sad -> ":white_frowning_face:"
    Feedback.EmojiFeedback.SlightlySad -> ":confused:"
    Feedback.EmojiFeedback.Neutral -> ":neutral_face:"
    Feedback.EmojiFeedback.SlightlyHappy -> ":slightly_smiling_face:"
    Feedback.EmojiFeedback.Happy -> ":grinning:"
    else -> ""
  }
}

fun Feedback.EmojiFeedback.toHTML(): String {
  return when (this) {
    Feedback.EmojiFeedback.Sad -> "&#x1F641;"
    Feedback.EmojiFeedback.SlightlySad -> "&#x1F615;"
    Feedback.EmojiFeedback.Neutral -> "&#x1F610;"
    Feedback.EmojiFeedback.SlightlyHappy -> "&#x1F642;"
    Feedback.EmojiFeedback.Happy -> "&#x1F600;"
    else -> ""
  }
}
