import components.navbar
import components.schnellbestellung.schnellbestellungForm
import components.schnellbestellung.schnellbestellungKomplettpaketDetails
import components.schnellbestellung.schnellbestellungOverview
import de.wunschsolar.model.products.Bundle
import kotlinx.browser.window
import react.*
import redux.WunschsolarAppState


fun RBuilder.schnellbestellungKomplettpaket(
  appState: WunschsolarAppState,
  bundle: Bundle,
): Unit = child(schnellbestellungKomplettpaket) {
  attrs {
    this.appState = appState
    this.bundle = bundle
  }
}

val schnellbestellungKomplettpaket: FC<SchnellbestellungKomplettpaketProps> = fc("schnellbestellunKomplettpaket") { props ->

  useEffectOnce {
    window.scrollTo(0.0, 0.0)
  }

  navbar()

  schnellbestellungOverview(props.bundle)

  schnellbestellungKomplettpaketDetails(props.bundle)

  schnellbestellungForm(props.appState, props.bundle)

  feedback()

  wunschsolarFooter()
}


external interface SchnellbestellungKomplettpaketProps : Props {
  var appState: WunschsolarAppState
  var bundle: Bundle
}
