package de.wunschsolar.model.products

import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.currency.EUR
import it.neckar.open.unit.other.pct
import de.wunschsolar.model.products.Product.ProductId
import it.neckar.uuid.HasUuid
import kotlinx.serialization.Serializable

/**
 * Represents a component which may be sold separately, such as a battery,
 *   or/and bundled together in a package
 */
@Serializable
data class ProductComponent(
  /**
   * [ProductId] of the [ProductComponent]
   */
  override val id: ProductId,

  /**
   * Name signifying the [ProductComponent]
   */
  override val description: String,

  /**
   * Price for this [ProductComponent]
   * Note that this price is for 1 unit
   *   multiple [ProductComponent] are handled with [ProductComponentConfiguration]
   */
  override val price: @EUR Double,
  /**
   * Markup for this component
   * The default is 30%
   */
  val markup: @pct Double = 30.percent,

  /**
   * Path to the image to be displayed in the carousel for this [ProductComponent]
   */
  override val imagePath: String? = null,

  val documentPath: String? = null,

  ) : Product, HasUuid {
  /**
   * Price for this [ProductComponent] with the markup included
   */
  val priceWithMarkup: @EUR Double = price * (100.percent + markup)
}
