package redux.actions

import de.wunschsolar.model.ValidatedDiscountCode
import redux.WunschsolarAppState


/**
 * Updates the order state
 */
class UpdateDiscountCodeAction(val newAppliedDiscount: ValidatedDiscountCode) : StateAction {
  override fun WunschsolarAppState.updateState(): WunschsolarAppState {
    return this.copy(appliedDiscount = newAppliedDiscount)
  }
}
