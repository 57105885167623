package components.schnellbestellung

import components.modals.compatabilityOverview
import de.wunschsolar.model.products.Bundle
import kotlinx.css.*
import kotlinx.html.classes
import maxSiteWidth
import react.*
import react.dom.*
import styled.*


/**
 * A summary of all the order information
 * and the button to confirm the order
 */
fun RBuilder.schnellbestellungWallboxDetails(
  bundle: Bundle,
): Unit = child(schnellbestellungWallboxDetails) {
  attrs {
    this.bundle = bundle
  }
}

val schnellbestellungWallboxDetails: FC<SchnellbestellungWallboxDetailsProps> = fc("schnellbestellungWallboxDetails") { props ->

  val product = props.bundle


  styledDiv {
    attrs {
      classes = setOf("container", "mt-5", "px-4")
    }
    css {
      maxWidth = maxSiteWidth
    }

    div(classes = "row gy-3") {
      h3(classes = "col-12 mb-0") {
        +"Details"
      }

      wallboxContentOverview(product)

      product.compatability?.let {
        compatabilityOverview(it)
      }

      //ablaufWallboxOverview()
    }
  }
}


external interface SchnellbestellungWallboxDetailsProps : Props {
  var bundle: Bundle
}
