@file:UseSerializers(UuidSerializer::class)

package de.wunschsolar.model.products

import com.benasher44.uuid.Uuid
import it.neckar.open.unit.currency.EUR
import it.neckar.uuid.HasUuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Interface for anything that is considered a product for this online store
 * So both individual components and packages of multiple components bundled together
 */
interface Product : HasUuid {
  val id: ProductId
  val description: String
  val price: @EUR Double
  val imagePath: String?

  override val uuid: Uuid
    get() = id.uuid

  @Serializable
  data class ProductId(val uuid: Uuid) {
    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): ProductId {
        return ProductId(randomUuid4())
      }
    }
  }
}
