import components.navbar
import components.schnellbestellung.schnellbestellungForm
import components.schnellbestellung.schnellbestellungOverview
import components.schnellbestellung.schnellbestellungWallboxDetails
import de.wunschsolar.model.products.Bundle
import kotlinx.browser.window
import react.*
import redux.WunschsolarAppState


fun RBuilder.schnellbestellungWallbox(
  appState: WunschsolarAppState,
  bundle: Bundle,
): Unit = child(schnellbestellungWallbox) {
  attrs {
    this.appState = appState
    this.bundle = bundle
  }
}

val schnellbestellungWallbox: FC<SchnellbestellungWallboxProps> = fc("schnellbestellungWallbox") { props ->

  useEffectOnce {
    window.scrollTo(0.0, 0.0)
  }

  navbar()

  schnellbestellungOverview(props.bundle)

  schnellbestellungWallboxDetails(props.bundle)

  schnellbestellungForm(props.appState, props.bundle)

  feedback()

  wunschsolarFooter()
}


external interface SchnellbestellungWallboxProps : Props {
  var appState: WunschsolarAppState
  var bundle: Bundle
}
