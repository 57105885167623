package it.neckar.rest.jwt

import io.ktor.http.*
import io.ktor.util.*
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Represents a JWT token
 */
@Serializable
@JvmInline
value class JwtToken(val token: String) {
  init {
    require(token.isNotBlank()) { "Token must not be blank" }
    require(token.contains("Bearer").not()) {
      "Token must not contain the Bearer prefix. Probably adding the complete auth header?"
    }
  }

  override fun toString(): String {
    return token
  }

  /**
   * Parses a JWT token payload.
   * The Token consists of three parts: Header.Payload.Signature
   */
  fun extractJwtPayload(): String {
    val parts = token.split('.')
    val header = parts[0]
    val signature = parts[2]
    val base64UrlPayload = parts[1]

    return base64UrlPayload.decodeBase64String()
  }
}

/**
 * Appends the JWT token to the header
 *
 * Attention: Use the `io.ktor.server.auth.Authentication` plugin if possible!
 */
fun HeadersBuilder.appendJwtTokenBearer(jwtToken: JwtToken) {
  append(HttpHeaders.Authorization, "Bearer $jwtToken")
}
