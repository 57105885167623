package components.schnellbestellung.forms

import components.schnellbestellung.forms.inputs.CustomerInformationInputs
import components.schnellbestellung.forms.inputs.inputFieldDataAndLabel
import de.wunschsolar.model.DiscountCode
import it.neckar.react.common.*
import kotlinx.coroutines.*
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import services.UiActions


/**
 * The payment selection
 * Currently, there is only one payment option available, so it currently requires no user input
 */
fun RBuilder.discountForm(
  customerInformationInputs: CustomerInformationInputs,
): Unit = child(discountForm) {
  attrs {
    this.customerInformationInputs = customerInformationInputs
  }
}

val discountForm: FC<DiscountFormProps> = fc("discountForm") { props ->

  div(classes = "row") {
    attrs {
      id = "additionalInformation-container"
    }

    h3(classes = "mb-4 col-12") { +"6. Rabattcode" }

    div(classes = "col-10") {
      inputFieldDataAndLabel(
        inputFieldData = props.customerInformationInputs.discountCode,
        fieldName = null,
        placeholder = "Hier Ihren Rabattcode eingeben",
        validFeedback = "Rabattcode angewendet!",
        invalidFeedback = "Dieser Rabattcode ist leider nicht gültig!",
      )
    }
    div("col-2") {

      val busyState = useState(false)
      button(classes = "btn btn-dark shadow-none mx-0", type = ButtonType.button) {
        attrs {
          disabled = busyState.value
          onClickFunction = useCallback(props.customerInformationInputs.discountCode) {
            props.customerInformationInputs.discountCode.value.let {
              if (it != null) {
                val newDiscountCode = DiscountCode(it.uppercase())

                AppScope.launch {
                  busyState.setter(true)
                  try {
                    UiActions.validateDiscountRequest(newDiscountCode)
                  } finally {
                    busyState.setter(false)
                  }
                }
              }
            }
          }
        }

        if (busyState.value) {
          i("spinner-border spinner-border-sm") { }
        } else {
          +"Einlösen"
        }
      }
    }
  }
}


external interface DiscountFormProps : Props {
  var customerInformationInputs: CustomerInformationInputs
}
