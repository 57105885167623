package de.wunschsolar.model.products

import com.benasher44.uuid.uuidFrom
import it.neckar.open.kotlin.lang.percent
import de.wunschsolar.model.products.Product.ProductId

/**
 * List all the [ProductComponent]s the online store uses
 * These [ProductComponent] are wrapped into [Bundle]s to be displayed on the store
 */
object AvailableComponents {
  val modul370IBC: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("2cc1a42a-9d87-4f15-8b94-3d1c9a80d03d")),
    description = "monokristalline Module IBC MonoSol 370 CS9-HC mit 25 Jahren Herstellergarantie",
    price = 141.60,
    documentPath = "datenblaetter/for-all-packages/Datenblatt_IBC-MonoSol-370-375-CS9-HC.pdf",
  )

  val modul375IBC: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("f30ee6dd-8add-4737-a613-76cae6935a57")),
    description = "monokristalline Module IBC MonoSol 375 CS9-HC mit 25 Jahren Herstellergarantie",
    price = 141.60,
    documentPath = "datenblaetter/for-all-packages/Datenblatt_IBC-MonoSol-370-375-CS9-HC.pdf",
  )


  val wechselrichterSMASB3_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("d36c3935-e1ea-4c34-bc46-463537a640c0")),
    description = "Wechselrichter SB 3.0 von SMA (dreiphasiger 3 KW Wechselrichter)",
    price = 801.60,
    imagePath = "img/komplettpaket-commons/2_sma_sb.png",
    documentPath = "datenblaetter/3-4,5/Datenblatt_SMA-SB-3.0-5.0.pdf",
  )

  val wechselrichterSMASB4_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("5b16ab95-0a95-44b8-a0d6-50d51e1fa190")),
    description = "Wechselrichter SB 4.0 von SMA (dreiphasiger 4 KW Wechselrichter)",
    price = 895.20,
    imagePath = "img/komplettpaket-commons/2_sma_sb.png",
    documentPath = "datenblaetter/3-4,5/Datenblatt_SMA-SB-3.0-5.0.pdf",
  )

  val wechselrichterSMASB5_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("32739ad5-d81c-4867-80d3-e4f1565926ff")),
    description = "Wechselrichter SB 5.0 von SMA (dreiphasiger 5 KW Wechselrichter)",
    price = 961.20,
    imagePath = "img/komplettpaket-commons/2_sma_sb.png",
    documentPath = "datenblaetter/6/Datenblatt_SMA-SB-3.0-5.0.pdf",
  )

  val wechselrichterSMASTP6_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("c48ef7fb-a9c4-4507-a979-5c6dc65a8cb7")),
    description = "Wechselrichter STP 6.0 von SMA (dreiphasiger 6 KW Wechselrichter)",
    price = 1_288.80,
    imagePath = "img/komplettpaket-commons/2_sma_stp.png",
    documentPath = "datenblaetter/6,75/Datenblatt_SMA-STP-6.0.pdf",
  )

  val wechselrichterSMASTP8_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("ff445ee6-4645-4178-b72d-a0262eeb029b")),
    description = "Wechselrichter STP 8.0 von SMA (dreiphasiger 8 KW Wechselrichter)",
    price = 1_544.40,
    imagePath = "img/komplettpaket-commons/2_sma_stp.png",
    documentPath = "datenblaetter/8,25-9,9-12/Datenblatt_SMA-STP-8.0-10.0.pdf",
  )

  val wechselrichterSMASTP10_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("ff445ee6-4645-4178-b72d-a0262eeb029b")),
    description = "Wechselrichter STP 10.0 von SMA (dreiphasiger 10 KW Wechselrichter)",
    price = 1_736.40,
    imagePath = "img/komplettpaket-commons/2_sma_stp.png",
    documentPath = "datenblaetter/8,25-9,9-12/Datenblatt_SMA-STP-8.0-10.0.pdf",
  )


  val batteryHVS5_1KWhSB2_5SMH2_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("1b353780-c1c9-4c8d-85e6-abacbf7e2dc0")),
    description = "Komplettpaket 5.1KWh HVS Speicher mit SBS2.5 + SHM2.0",
    price = 4_194.95,
    imagePath = "img/komplettpaket-commons/3_byd_bb_premium_hvs.png",
    documentPath = "datenblaetter/3-4,5/Datenblaetter_HVS-SBS2.5-SHM2.0.pdf",
  )

  val batteryHVS7_7KWhSBS3_7SMH2_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("30edc50b-16dd-4baf-be6b-b02eb6f342ff")),
    description = "Komplettpaket 7.7KWh HVS Speicher mit SBS3.7 + SHM2.0",
    price = 6_170.06,
    imagePath = "img/komplettpaket-commons/3_byd_bb_premium_hvs.png",
    documentPath = "datenblaetter/6/Datenblaetter_HVS-SBS3.7-SHM2.0.pdf",
  )

  val batteryBYDLVS8KWhSI4_4SMH2_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("a48986b1-7257-4192-8584-a9a9cbb50a53")),
    description = "BYD LVS 8 KWh Batterie komplett mit Sunny Island 4.4 + SHM2.0",
    price = 6_557.52,
    imagePath = "img/komplettpaket-commons/3_byd_bb_lvs.png",
    documentPath = "datenblaetter/6,75/Datenblaetter_LVS-SI4.4.pdf",
  )

  val batteryBYDLVS12KWhSI4_4SMH2_0: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("d9f218c2-9787-4615-b0e6-4365e7f8789f")),
    description = "BYD LVS 12 KWh Batterie komplett mit Sunny Island 4.4 + SHM2.0",
    price = 8_272.80,
    imagePath = "img/komplettpaket-commons/3_byd_bb_lvs.png",
    documentPath = "datenblaetter/8,25-9,9-12/Datenblatt_SMA-STP-8.0-10.0.pdf",
  )


  val montageSystem: ProductComponent = ProductComponent(
    ProductId(uuidFrom("8ce23135-ad76-4d52-a835-ffbdcae525f3")),
    description = "Würth Montagesystem mit lebenslanger Garantie",
    price = 0.00,
  )

  val solarkabel: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("6c665d6b-d2cb-41a8-b4ca-24eedc23afd1")),
    description = "Solarkabel 6 mm² (meter)",
    price = 0.82,
    imagePath = "img/komplettpaket-commons/4_100m_kabel.png",
  )

  val steckerMC4: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("6cbfa135-e01a-4ed0-922b-372d8fee598f")),
    description = "MC4 Stecker Paar",
    price = 3.60,
    imagePath = "img/komplettpaket-commons/5_mc4-stecker-paar.png"
  )

  val schrauben100er: ProductComponent = ProductComponent(
    ProductId(uuidFrom("9622fd90-8bbd-4807-813b-d33854910a8c")),
    description = "Schrauben 100er Edelstahl",
    price = 0.23,
  )

  val dachhaken100er: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("1c76b13e-27fe-4155-946f-c529dd626a38")),
    description = "Dachhaken 100er",
    price = 9.12,
  )

  val schiene39x37: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("a25a3a00-11a5-42c3-9157-bab1e3f9626f")),
    description = "Schiene 39x37",
    price = 5.52,
  )

  val schienenverbinder39x37: ProductComponent = ProductComponent(
    ProductId(uuidFrom("098dd823-07ca-4144-9072-0c1ef689bf61")),
    description = "Schienenverbinder 39x37",
    price = 2.88,
  )

  val mittelklemme: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("9853c578-6536-43b2-b9c3-7eba855cf031")),
    description = "Mittelklemme",
    price = 1.15,
  )

  val endklemme: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("c367e364-b93a-47bc-8572-f6e7bf8fb6e2")),
    description = "Endklemme",
    price = 1.44,
  )

  val endkappe: ProductComponent = ProductComponent(
    ProductId(uuidFrom("2255cd2f-5d11-4868-9ce7-60ff83b1cafd")),
    description = "Endkappe",
    price = 0.66,
  )

  val kleinteile: ProductComponent = ProductComponent(
    ProductId(uuidFrom("eedbc910-a88d-465f-8581-488843648ea2")),
    description = "Kleinteile, Kabelbinder, Clips, etc.",
    price = 24.0,
  )


  val wallboxABL1w1108emh1: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("97440685-822f-4adc-a797-372eff356b52")),
    description = "ABL eMH1 Basic 1W1108 Wallbox",
    price = 624.00,
    documentPath = "datenblaetter/wallboxes/ABL Wallbox 1W1108 eMH1 Basic_Datenblatt_DE.pdf",
  )

  val wallboxSMAEVcharger22: ProductComponent = ProductComponent(
    id = ProductId(uuidFrom("97440685-822f-4adc-a797-372eff356b52")),
    description = "SMA EV Charger 22 Wallbox",
    price = 1_452.00,
    markup = 20.percent,
    documentPath = "datenblaetter/wallboxes/SMA EV Charger 22_Datenblatt_DE.pdf",
  )
}
