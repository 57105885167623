package de.wunschsolar.model.products

import com.benasher44.uuid.uuidFrom
import de.wunschsolar.model.products.AvailableComponents.batteryBYDLVS12KWhSI4_4SMH2_0
import de.wunschsolar.model.products.AvailableComponents.batteryBYDLVS8KWhSI4_4SMH2_0
import de.wunschsolar.model.products.AvailableComponents.batteryHVS5_1KWhSB2_5SMH2_0
import de.wunschsolar.model.products.AvailableComponents.batteryHVS7_7KWhSBS3_7SMH2_0
import de.wunschsolar.model.products.AvailableComponents.dachhaken100er
import de.wunschsolar.model.products.AvailableComponents.endkappe
import de.wunschsolar.model.products.AvailableComponents.endklemme
import de.wunschsolar.model.products.AvailableComponents.kleinteile
import de.wunschsolar.model.products.AvailableComponents.mittelklemme
import de.wunschsolar.model.products.AvailableComponents.modul370IBC
import de.wunschsolar.model.products.AvailableComponents.modul375IBC
import de.wunschsolar.model.products.AvailableComponents.montageSystem
import de.wunschsolar.model.products.AvailableComponents.schiene39x37
import de.wunschsolar.model.products.AvailableComponents.schienenverbinder39x37
import de.wunschsolar.model.products.AvailableComponents.schrauben100er
import de.wunschsolar.model.products.AvailableComponents.solarkabel
import de.wunschsolar.model.products.AvailableComponents.steckerMC4
import de.wunschsolar.model.products.AvailableComponents.wallboxABL1w1108emh1
import de.wunschsolar.model.products.AvailableComponents.wallboxSMAEVcharger22
import de.wunschsolar.model.products.AvailableComponents.wechselrichterSMASB3_0
import de.wunschsolar.model.products.AvailableComponents.wechselrichterSMASB4_0
import de.wunschsolar.model.products.AvailableComponents.wechselrichterSMASB5_0
import de.wunschsolar.model.products.AvailableComponents.wechselrichterSMASTP10_0
import de.wunschsolar.model.products.AvailableComponents.wechselrichterSMASTP6_0
import de.wunschsolar.model.products.AvailableComponents.wechselrichterSMASTP8_0
import de.wunschsolar.model.products.Product.ProductId

/**
 * All the available packages to be displayed in the store
 * Contains [Bundle]s which contain a list of [ProductComponentConfiguration]s as their content
 */
object AvailableBundles {

  val wallboxABL1w1108emh1Bundle: Bundle = Bundle(
    id = ProductId(uuidFrom("0276377c-15f0-4ade-9180-8b3fc43a4d4c")),
    description = "ABL eMH1 Basic 1W1108",
    shortName = "ABL eMH1 Basic 1W1108",
    imagePath = "img/wallboxes/abl-wallbox-emh1-basic.png",
    contentHeader = "",
    content = listOf(ProductComponentConfiguration(wallboxABL1w1108emh1)),
  )

  val wallboxSMAEVcharger22Bundle: Bundle = Bundle(
    id = ProductId(uuidFrom("74fe1184-ebc9-4395-9696-65bf8ed61848")),
    description = "SMA EV Charger 22",
    shortName = "SMA EV Charger 22",
    imagePath = "img/wallboxes/sma-EV-Charger.png",
    contentHeader = "",
    content = listOf(ProductComponentConfiguration(wallboxSMAEVcharger22)),
  )


  val complete3KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("01b119ef-1fa8-4958-b852-6170533b207d")),
    description = "Komplettpaket 3KWp PV Anlage",
    shortName = "Komplettpaket 3KWp",
    imagePath = "img/komplettpaket-3kWp/0_paket-3kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 3KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 8,
        imagePathOverride = "img/komplettpaket-3kWp/1_8x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASB3_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 84),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 28,
        imagePathOverride = "img/komplettpaket-3kWp/6_28x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 20,
        imagePathOverride = "img/komplettpaket-3kWp/7_20x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 4),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 16,
        imagePathOverride = "img/komplettpaket-3kWp/8_16x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 8,
        imagePathOverride = "img/komplettpaket-3kWp/9_8x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 8),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 18 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 546.00,
  )

  val complete3KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("01b119ef-1fa8-4958-b852-6170533b207d")),
    description = "Komplettpaket 3KWp PV Anlage mit 5,1KWh Speicher",
    shortName = "Komplettpaket 3KWp",
    imagePath = "img/komplettpaket-3kWp/0_paket-3kWp.png",
    contentHeader = "Das Komplettpaket 3KWp PV Anlage mit 5,1KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 8,
        imagePathOverride = "img/komplettpaket-3kWp/1_8x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASB3_0),
      ProductComponentConfiguration(batteryHVS5_1KWhSB2_5SMH2_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 84),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 28,
        imagePathOverride = "img/komplettpaket-3kWp/6_28x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 20,
        imagePathOverride = "img/komplettpaket-3kWp/7_20x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 4),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 16,
        imagePathOverride = "img/komplettpaket-3kWp/8_16x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 8,
        imagePathOverride = "img/komplettpaket-3kWp/9_8x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 8),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 18 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )


  val complete4_5KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("79b4c6ec-c32b-4460-a87d-cce314279380")),
    description = "Komplettpaket 4,5KWp PV Anlage",
    shortName = "Komplettpaket 4,5KWp",
    imagePath = "img/komplettpaket-4,5kWp/0_paket-4,5kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 4,5KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 12,
        imagePathOverride = "img/komplettpaket-4,5kWp/1_12x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASB4_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 126),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 42,
        imagePathOverride = "img/komplettpaket-4,5kWp/6_42x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 30,
        imagePathOverride = "img/komplettpaket-4,5kWp/7_30x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 6),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 24,
        imagePathOverride = "img/komplettpaket-4,5kWp/8_24x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 12,
        imagePathOverride = "img/komplettpaket-4,5kWp/9_12x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 12),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 27 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 546.00,
  )

  val complete4_5KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("79b4c6ec-c32b-4460-a87d-cce314279380")),
    description = "Komplettpaket 4,5KWp PV Anlage mit 5,1KWh Speicher",
    shortName = "Komplettpaket 4,5KWp",
    imagePath = "img/komplettpaket-4,5kWp/0_paket-4,5kWp.png",
    contentHeader = "Das Komplettpaket 4,5KWp PV Anlage mit 5,1KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 12,
        imagePathOverride = "img/komplettpaket-4,5kWp/1_12x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASB4_0),
      ProductComponentConfiguration(batteryHVS5_1KWhSB2_5SMH2_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 126),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 42,
        imagePathOverride = "img/komplettpaket-4,5kWp/6_42x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 30,
        imagePathOverride = "img/komplettpaket-4,5kWp/7_30x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 6),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 24,
        imagePathOverride = "img/komplettpaket-4,5kWp/8_24x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 12,
        imagePathOverride = "img/komplettpaket-4,5kWp/9_12x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 12),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 27 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )


  val complete6KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("0c48cac4-70e7-493e-aaf8-12128fd874ac")),
    description = "Komplettpaket 6KWp PV Anlage",
    shortName = "Komplettpaket 6KWp",
    imagePath = "img/komplettpaket-6kWp/0_paket-6kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 6KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 16,
        imagePathOverride = "img/komplettpaket-6kWp/1_16x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASB5_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 168),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 56,
        imagePathOverride = "img/komplettpaket-6kWp/6_56x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 40,
        imagePathOverride = "img/komplettpaket-6kWp/7_40x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 10),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 34,
        imagePathOverride = "img/komplettpaket-6kWp/8_34x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 16,
        imagePathOverride = "img/komplettpaket-6kWp/9_16x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 16),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 36 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 624.00,
  )

  val complete6KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("0c48cac4-70e7-493e-aaf8-12128fd874ac")),
    description = "Komplettpaket 6KWp PV Anlage mit 7,7KWh Speicher",
    shortName = "Komplettpaket 6KWp",
    imagePath = "img/komplettpaket-6kWp/0_paket-6kWp.png",
    contentHeader = "Das Komplettpaket 6KWp PV Anlage mit 7,7KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 16,
        imagePathOverride = "img/komplettpaket-6kWp/1_16x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASB5_0),
      ProductComponentConfiguration(batteryHVS7_7KWhSBS3_7SMH2_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 168),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 56,
        imagePathOverride = "img/komplettpaket-6kWp/6_56x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 40,
        imagePathOverride = "img/komplettpaket-6kWp/7_40x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 10),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 34,
        imagePathOverride = "img/komplettpaket-6kWp/8_34x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 16,
        imagePathOverride = "img/komplettpaket-6kWp/9_16x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 16),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 36 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )


  val complete6_75KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("54b4e555-53eb-4225-b8c6-5f8c75acac3c")),
    description = "Komplettpaket 6,75KWp PV Anlage",
    shortName = "Komplettpaket 6,75KWp",
    imagePath = "img/komplettpaket-6,75kWp/0_paket-6,75kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 6,75KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul370IBC,
        amount = 18,
        imagePathOverride = "img/komplettpaket-6,75kWp/1_18x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP6_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 200),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 64,
        imagePathOverride = "img/komplettpaket-6,75kWp/6_64x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 46,
        imagePathOverride = "img/komplettpaket-6,75kWp/7_46x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 10),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 36,
        imagePathOverride = "img/komplettpaket-6,75kWp/8_36x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 12,
        imagePathOverride = "img/komplettpaket-6,75kWp/9_12x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 12),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 41 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 624.00,
  )

  val complete6_75KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("54b4e555-53eb-4225-b8c6-5f8c75acac3c")),
    description = "Komplettpaket 6,75KWp PV Anlage mit 8KWh Speicher",
    shortName = "Komplettpaket 6,75KWp",
    imagePath = "img/komplettpaket-6,75kWp/0_paket-6,75kWp.png",
    contentHeader = "Das Komplettpaket 6,75KWp PV Anlage mit 8KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul370IBC,
        amount = 18,
        imagePathOverride = "img/komplettpaket-6,75kWp/1_18x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP6_0),
      ProductComponentConfiguration(batteryBYDLVS8KWhSI4_4SMH2_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 200),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 64,
        imagePathOverride = "img/komplettpaket-6,75kWp/6_64x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 46,
        imagePathOverride = "img/komplettpaket-6,75kWp/7_46x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 10),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 36,
        imagePathOverride = "img/komplettpaket-6,75kWp/8_36x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 12,
        imagePathOverride = "img/komplettpaket-6,75kWp/9_12x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 12),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 41 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )


  val complete8_25KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("fde196d1-e36c-4527-90ee-552675e56a0c")),
    description = "Komplettpaket 8,25KWp PV Anlage",
    shortName = "Komplettpaket 8,25KWp",
    imagePath = "img/komplettpaket-8,25kWp/0_paket-8,25kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 8,25KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 22,
        imagePathOverride = "img/komplettpaket-8,25kWp/1_22x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP8_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 300),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 90,
        imagePathOverride = "img/komplettpaket-8,25kWp/6_90x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 64,
        imagePathOverride = "img/komplettpaket-8,25kWp/7_64x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 14),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 46,
        imagePathOverride = "img/komplettpaket-8,25kWp/8_46x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 16,
        imagePathOverride = "img/komplettpaket-8,25kWp/9_16x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 16),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 50 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 702.00,
  )

  val complete8_25KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("fde196d1-e36c-4527-90ee-552675e56a0c")),
    description = "Komplettpaket 8,25KWp PV Anlage mit 8KWh Speicher",
    shortName = "Komplettpaket 8,25KWp",
    imagePath = "img/komplettpaket-8,25kWp/0_paket-8,25kWp.png",
    contentHeader = "Das Komplettpaket 8,25KWp PV Anlage mit 8KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 22,
        imagePathOverride = "img/komplettpaket-8,25kWp/1_22x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(batteryBYDLVS8KWhSI4_4SMH2_0),
      ProductComponentConfiguration(wechselrichterSMASTP8_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 300),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 90,
        imagePathOverride = "img/komplettpaket-8,25kWp/6_90x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 64,
        imagePathOverride = "img/komplettpaket-8,25kWp/7_64x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 14),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 46,
        imagePathOverride = "img/komplettpaket-8,25kWp/8_46x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 16,
        imagePathOverride = "img/komplettpaket-8,25kWp/9_16x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 16),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 50 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )


  val complete9_9KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("38e23bf9-16ac-44f3-8aa7-70f3c935451d")),
    description = "Komplettpaket 9,9KWp PV Anlage",
    shortName = "Komplettpaket 9,9KWp",
    imagePath = "img/komplettpaket-9,9kWp/0_paket-10kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 9,9KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul370IBC,
        amount = 27,
        imagePathOverride = "img/komplettpaket-9,9kWp/1_27x_ibc-monosol-370.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP8_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 300),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 90,
        imagePathOverride = "img/komplettpaket-9,9kWp/6_90x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 64,
        imagePathOverride = "img/komplettpaket-9,9kWp/7_64x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 14),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 60,
        imagePathOverride = "img/komplettpaket-9,9kWp/8_60x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 20,
        imagePathOverride = "img/komplettpaket-9,9kWp/9_20x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 20),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 60 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 702.00,
  )

  val complete9_9KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("38e23bf9-16ac-44f3-8aa7-70f3c935451d")),
    description = "Komplettpaket 9,9KWp PV Anlage mit 8KWh Speicher",
    shortName = "Komplettpaket 9,9KWp",
    imagePath = "img/komplettpaket-9,9kWp/0_paket-10kWp.png",
    contentHeader = "Das Komplettpaket 9,9KWp PV Anlage mit 8KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul370IBC,
        amount = 27,
        imagePathOverride = "img/komplettpaket-9,9kWp/1_27x_ibc-monosol-370.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP8_0),
      ProductComponentConfiguration(batteryBYDLVS8KWhSI4_4SMH2_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 300),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 90,
        imagePathOverride = "img/komplettpaket-9,9kWp/6_90x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 64,
        imagePathOverride = "img/komplettpaket-9,9kWp/7_64x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 14),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 60,
        imagePathOverride = "img/komplettpaket-9,9kWp/8_60x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 20,
        imagePathOverride = "img/komplettpaket-9,9kWp/9_20x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 20),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 60 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )


  val complete12KWpWithoutBatteryBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("8ec5e317-fec0-40f7-8357-22814e048f49")),
    description = "Komplettpaket 12KWp PV Anlage",
    shortName = "Komplettpaket 12KWp",
    imagePath = "img/komplettpaket-12kWp/0_paket-12kWp_ohne-speicher.png",
    contentHeader = "Das Komplettpaket 12KWp PV Anlage enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 32,
        imagePathOverride = "img/komplettpaket-12kWp/1_32x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP10_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 340),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 112,
        imagePathOverride = "img/komplettpaket-12kWp/6_112x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 80,
        imagePathOverride = "img/komplettpaket-12kWp/7_80x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 16),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 66,
        imagePathOverride = "img/komplettpaket-12kWp/8_66x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 24,
        imagePathOverride = "img/komplettpaket-12kWp/9_24x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 24),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 72 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
    additionalMarkup = 702.00,
  )

  val complete12KWpBundle: Bundle = Bundle(
    id = ProductId(uuidFrom("8ec5e317-fec0-40f7-8357-22814e048f49")),
    description = "Komplettpaket 12KWp PV Anlage mit 12KWh Speicher",
    shortName = "Komplettpaket 12KWp",
    imagePath = "img/komplettpaket-12kWp/0_paket-12kWp.png",
    contentHeader = "Das Komplettpaket 12KWp PV Anlage mit 12KWh Speicher enthält alles, was Sie für Ihr PV-Projekt benötigen:",
    content = listOf(
      ProductComponentConfiguration(
        productComponent = modul375IBC,
        amount = 32,
        imagePathOverride = "img/komplettpaket-12kWp/1_32x_ibc-monosol-375.png",
      ),
      ProductComponentConfiguration(wechselrichterSMASTP10_0),
      ProductComponentConfiguration(batteryBYDLVS12KWhSI4_4SMH2_0),
      ProductComponentConfiguration(montageSystem),
      ProductComponentConfiguration(solarkabel, 100),
      ProductComponentConfiguration(steckerMC4, 2),
      ProductComponentConfiguration(schrauben100er, 340),
      ProductComponentConfiguration(
        productComponent = dachhaken100er,
        amount = 112,
        imagePathOverride = "img/komplettpaket-12kWp/6_112x_wuerth-dachhaken.png",
      ),
      ProductComponentConfiguration(
        productComponent = schiene39x37,
        amount = 80,
        imagePathOverride = "img/komplettpaket-12kWp/7_80x_wuerth-montageschiene-39x37.png",
      ),
      ProductComponentConfiguration(schienenverbinder39x37, 16),
      ProductComponentConfiguration(
        productComponent = mittelklemme,
        amount = 66,
        imagePathOverride = "img/komplettpaket-12kWp/8_66x_wuerth-mittelklemme.png",
      ),
      ProductComponentConfiguration(
        productComponent = endklemme,
        amount = 24,
        imagePathOverride = "img/komplettpaket-12kWp/9_24x_wuerth-endklemme.png",
      ),
      ProductComponentConfiguration(endkappe, 24),
      ProductComponentConfiguration(kleinteile),
    ),
    optionalContent = listOf(
      "Netzvoranfrage / Klärung mit dem Netzbetreiber",
      "Elektroinstallation vor Ort",
      "Inbetriebnahme PV Anlage und Anmeldung beim Netzbetreiber",
    ),
    compatability = listOf(
      "Satteldächer mit nutzbaren Flächen von mindestens 72 m²",
      "Ziegeleindeckung (Frankfurter Pfanne o.ä.)",
    ),
  )
}
