import it.neckar.common.featureflags.FeatureFlagsSupport
import kotlinx.browser.document
import react.*
import react.dom.client.*
import redux.RAction
import redux.Store
import redux.WunschsolarAppState
import redux.actions.reduceAction
import redux.createStore
import redux.rEnhancer

/**
 * The global app state
 */
//TODO replace with undo store
val store: Store<WunschsolarAppState, RAction, dynamic> = createStore<WunschsolarAppState, RAction, dynamic>(
  ::reduceAction,
  WunschsolarAppState(),
  rEnhancer()
).also {
  it.subscribe {
    println("Store updated")
  }
}

/**
 * Main entry point for wunschsolar.de
 */
fun main() {
  println("Starting wunschsolar.de")

  console.log("Active Feature Flags: ${FeatureFlagsSupport.flags}")

  // Start the React app
  val container = web.dom.document.getElementById("root") ?: throw IllegalArgumentException("Element root not found")
  val root = createRoot(container)
  // TODO: double check if there is a cleaner way to do this with a future release
  root.render(createElement<Props> {
    wunschsolarApp()
  } as ReactNode)
}
