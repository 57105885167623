package components.modals

import it.neckar.react.common.*
import react.*
import react.dom.*


/**
 * The confirmation modal to be displayed when the submit button is pressed and all field inputs are valid
 */
fun RBuilder.widerrufsbelehrungModal(): Unit = child(widerrufsbelehrungModal) {}

val widerrufsbelehrungModal: FC<WiderrufsbelehrungModalProps> = fc("widerrufsbelehrungModal") {

  modal(
    id = "widerrufsbelehrung",
    title = "Widerrufsbelehrung für die Lieferung von Waren",
    modalCentered = true,
  ) {
    h1 {
      +"Leider ist zur Zeit keine Bestellung möglich!"
    }
  }

  modal(
    id = "widerrufsbelehrung",
    title = "Widerrufsbelehrung für die Lieferung von Waren",
    modalCentered = true,
  ) {

    p {
      +"Das Widerrufsrecht gilt nicht für gewerbliche Kunden"
    }

    p {
      +"Widerrufsrecht"
    }

    p {
      +"Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat."
    }

    p {
      +"Um Ihr Widerrufsrecht auszuüben, müssen Sie uns:"
    }
    p {
      +"Lizergy GmbH"
      br {}
      +"Jaspisstrasse 13"
      br {}
      +"72250 Freudenstadt"
      br {}

      +"E-Mail: "
      a(href = "mailto:info@lizergy.com") {
        +"info@lizergy.com"
      }

      br {}

      +"Telefon: "
      a(href = "tel:+074419521900") {
        +"074419521900"
      }

      br {}

      +"Fax: "
      a(href = "fax:074419521909") {
        +"074419521909"
      }
    }
    p {
      +"mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist."
    }

    p {
      i(classes = "far fa-file-pdf pe-2") {}
      a(href = "widerrufsformular-lizergy.pdf", classes = "link-dark", target = "_blank") {
        +"Widerrufsformular"
      }
    }

    p {
      +"Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden."
    }

    p {
      +"Folgen des Widerrufs:"
    }

    p {
      +"Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet."
    }

    p {
      +"Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns (Lizergy GmbH, Jaspisstrasse 13, 72250 Freudenstadt) zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Bei Waren, die aufgrund ihrer Beschaffenheit nicht normal mit der Post zurückgesandt werden können, betragen diese 400 EUR. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit Ihnen zurückzuführen ist. Das Widerrufsrecht besteht nicht bei den folgenden Verträgen:"
    }
    div {
      ul {
        li { +"Verträge zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind." }
        li { +"zur Lieferung von Waren an Wiederverkäufer und Unternehmen." }
      }
    }
  }
}


external interface WiderrufsbelehrungModalProps : Props
