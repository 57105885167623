package redux.actions

import redux.OrderState
import redux.WunschsolarAppState


/**
 * Updates the order state
 */
class UpdateOrderStateAction(val newOrderState: OrderState) : StateAction {
  override fun WunschsolarAppState.updateState(): WunschsolarAppState {
    return this.copy(orderState = newOrderState)
  }
}
