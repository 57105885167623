package components.modals

import it.neckar.open.collections.fastForEach
import react.*
import react.dom.*

fun RBuilder.compatabilityOverview(compatability: List<String>) {
  div {
    h6(classes = "col-12 mb-1") {
      +"Kompatibilität"
    }

    p(classes = "col-12 mb-1") {
      +"Dieses Komplettpaket eignet sich für:"
    }
    ul(classes = "row gy-2 px-2 mx-2") {
      compatability.fastForEach { compatabilityLine: String ->
        li(classes = "col-12") { +compatabilityLine }
      }
    }
  }
}
