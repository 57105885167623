import components.MainRoute
import components.complete12KWpBundleRoute
import components.complete12KWpWithoutBatteryBundleRoute
import components.complete3KWpBundleRoute
import components.complete3KWpWithoutBatteryBundleRoute
import components.complete4_5KWpBundleRoute
import components.complete4_5KWpWithoutBatteryBundleRoute
import components.complete6KWpBundleRoute
import components.complete6KWpWithoutBatteryBundleRoute
import components.complete6_75KWpBundleRoute
import components.complete6_75KWpWithoutBatteryBundleRoute
import components.complete8_25KWpBundleRoute
import components.complete8_25KWpWithoutBatteryBundleRoute
import components.complete9_9KWpBundleRoute
import components.complete9_9KWpWithoutBatteryBundleRoute
import components.ueberUnsRoute
import components.wallboxABL1w1108emh1BundleRoute
import components.wallboxSMAEVcharger22BundleRoute
import it.neckar.react.common.router.*
import react.*

val Navigation: NavigationRoot = buildNavigation {
  route("") {
    //element = { MainStage.create() }

    index { element = { MainRoute.create() } }

    route("Ueber-uns") {
      index { element = { ueberUnsRoute.create() } }
    }

    route("ABL-eMH1-Basic-1W1108-Wallbox") {
      index { element = { wallboxABL1w1108emh1BundleRoute.create() } }
    }
    route("SMA-EV-Charger-22") {
      index { element = { wallboxSMAEVcharger22BundleRoute.create() } }
    }

    route("3-KWp-Paket") {
      index { element = { complete3KWpBundleRoute.create() } }
    }
    route("3-KWp-Paket-ohne-Speicher") {
      index { element = { complete3KWpWithoutBatteryBundleRoute.create() } }
    }

    route("4_5-KWp-Paket") {
      index { element = { complete4_5KWpBundleRoute.create() } }
    }
    route("4_5-KWp-Paket-ohne-Speicher") {
      index { element = { complete4_5KWpWithoutBatteryBundleRoute.create() } }
    }

    route("6-KWp-Paket") {
      index { element = { complete6KWpBundleRoute.create() } }
    }
    route("6-KWp-Paket-ohne-Speicher") {
      index { element = { complete6KWpWithoutBatteryBundleRoute.create() } }
    }

    route("6_75-KWp-Paket") {
      index { element = { complete6_75KWpBundleRoute.create() } }
    }
    route("6_75-KWp-Paket-ohne-Speicher") {
      index { element = { complete6_75KWpWithoutBatteryBundleRoute.create() } }
    }

    route("8_25-KWp-Paket") {
      index { element = { complete8_25KWpBundleRoute.create() } }
    }
    route("8_25-KWp-Paket-ohne-Speicher") {
      index { element = { complete8_25KWpWithoutBatteryBundleRoute.create() } }
    }

    route("9_9-KWp-Paket") {
      index { element = { complete9_9KWpBundleRoute.create() } }
    }
    route("9_9-KWp-Paket-ohne-Speicher") {
      index { element = { complete9_9KWpWithoutBatteryBundleRoute.create() } }
    }

    route("12-KWp-Paket") {
      index { element = { complete12KWpBundleRoute.create() } }
    }
    route("12-KWp-Paket-ohne-Speicher") {
      index { element = { complete12KWpWithoutBatteryBundleRoute.create() } }
    }

  }
}
