package components.modals

import it.neckar.react.common.*
import react.*
import react.dom.*


/**
 * The confirmation modal to be displayed when the submit button is pressed and all field inputs are valid
 */
fun RBuilder.agbModal(): Unit = child(agbModal) {}

val agbModal: FC<AGBModalProps> = fc("agbModal") {
  if (true) {
    modal(
      id = "agbs",
      title = "Allgemeine Geschäftsbedingungen",
      modalCentered = true,
    ) {
      h3 {
        +"Aktuell ist Shop leider geschlossen"
      }
    }
    return@fc
  }

  modal(
    id = "agbs",
    title = "Allgemeine Geschäftsbedingungen",
    modalCentered = true,
  ) {

    p {
      +"Die vorliegenden Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Geschäftsbeziehungen mit der Lizergy GmbH und unseren Kunden. Abweichende oder entgegenstehende Allgemeine Geschäftsbedingungen des Kunden werden nur insoweit Vertragsbestandteil, als wir ihrer Geltung ausdrücklich zugestimmt haben. Als Zustimmung gilt nicht, wenn wir in Kenntnis der AGB des Kunden die Lieferung an ihn vorbehaltlos ausführen."
    }

    p {
      +"Vertragspartner ist:"
      br { }
      +"Lizergy GmbH"
      br { }
      +"Jaspisstrasse 13"
      br { }
      +"72250 Freudenstadt"
      br { }
      +"(nachfolgend Lizergy genannt)"
    }

    h5("mt-4") {
      +"1. Geltungsbereich"
    }
    p {
      +"Für alle Bestellungen über unseren Online-Shop durch Verbraucher und Unternehmer gelten die nachfolgenden AGB."
    }
    p {
      +"Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt."
    }
    p {
      +"Gegenüber Unternehmern gelten diese AGB auch für künftige Geschäftsbeziehungen, ohne dass wir nochmals auf sie hinweisen müssten. Verwendet der Unternehmer entgegenstehende oder ergänzende Allgemeine Geschäfts-bedingungen, wird deren Geltung hiermit widersprochen; sie werden nur dann Vertragsbestandteil, wenn wir dem ausdrücklich zugestimmt haben."
    }

    h5("mt-4") {
      +"2. Vertragspartner, Vertragsabschluss"
    }
    p {
      +"Der Kaufvertrag kommt zustande mit Lizergy GmbH. Die Darstellung der Produkte im Online -Shop stellt kein rechtlich bindendes Angebot, sondern einen unverbindlichen Online-Katalog dar. Sie können unsere Produkte zunächst unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen und erläuterten Korrekturhilfen nutzen. Durch Anklicken des Bestellbuttons geben Sie eine verbindliche Bestellung der im Warenkorb enthaltenen Waren ab. Die Bestätigung des Zugangs Ihrer Bestellung erfolgt per E-Mail unmittelbar nach dem Absenden der Bestellung."
    }
    p {
      +"Wann der Vertrag mit uns zustande kommt, richtet sich nach der von Ihnen gewählten Zahlungsart:"
    }
    h6 {
      +"Rechnung, Kreditkarte"
    }
    p {
      +"Wir nehmen Ihre Bestellung durch Versand einer Annahmeerklärung in separater E-Mail oder durch Auslieferung der Ware innerhalb von zwei Tagen an."
    }
    h6 {
      +"Vorkasse, Banküberweisung"
    }
    p {
      +"Wir nehmen Ihre Bestellung durch Versand einer Annahmeerklärung in separater E-Mail innerhalb von zwei Tagen an, in welcher wir Ihnen unsere Bankverbindung nennen."
    }

    h5("mt-4") {
      +"3. Vertragssprache, Vertragstextspeicherung"
    }
    p {
      +"Die für den Vertragsschluss zur Verfügung stehende Sprache ist Deutsch."
    }
    p {
      +"Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB per E-Mail zu. Die AGB können Sie jederzeit auch hier auf dieser Seite einsehen und herunterladen."
    }

    h5("mt-4") {
      +"4. Lieferbedingungen"
    }
    p {
      +"Zuzüglich zu den angegebenen Produktpreisen kommen noch Versandkosten hinzu. Näheres zur Höhe der Versandkosten erfahren Sie bei den Angeboten."
      br {}
      +"Wir liefern nicht an Packstationen."
    }

    h5("mt-4") {
      +"5. Bezahlung"
    }
    p {
      +"Die Zahlung erfolgt per Vorkasse, oder Rechnung. Lizergy behält sich das Recht vor, einzelne Zahlungsarten auszuschließen."
    }

    h6 {
      +"Zahlung per Banküberweisung"
    }
    p {
      +"Bei Auswahl der Zahlungsart Rechnung per Vorkasse nennen wir Ihnen unsere Bankverbindung in der Auftragsbestätigung und liefern die Ware nach Zahlungseingang. Der Zahlungseingang muss innerhalb von 10 Werktagen erfolgen."
    }

    h6 {
      +"Rechnung"
    }
    p {
      +"Sie zahlen den Rechnungsbetrag nach Erhalt der Ware und der Rechnung per Überweisung auf unser Bankkonto. Wir behalten uns vor, den Kauf auf Rechnung nur nach einer erfolgreichen Bonitätsprüfung anzubieten."
    }

    h5("mt-4") {
      +"6. Widerrufsrecht"
    }
    p {
      +"Verbrauchern steht das gesetzliche Widerrufsrecht wie in der Widerrufsbelehrung beschrieben zu."
    }

    h5("mt-4") {
      +"7. Eigentumsvorbehalt"
    }
    p {
      +"Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum."
    }

    h5("mt-4") {
      +"8. Lieferung"
    }
    p {
      +"Die Lieferung erfolgt an die in der Bestellung angegebene Lieferanschrift. Die Höhe der Transportkosten entnehmen Sie bitte den Versandinformationen. Der Vertragsschluss erfolgt unter dem Vorbehalt der richtigen und rechtzeitigen Selbstbelieferung durch Zulieferer von Lizergy. Der Kunde wird über die Nichtverfügbarkeit der Leistung unverzüglich informiert. Die Gegenleistung wird unverzüglich zurückerstattet. Lizergy ist zu Teillieferungen berechtigt, soweit für den Kunden Teillieferungen nicht unzumutbar sind. Wünscht der Kunde eine oder mehrere Teillieferungen, so trägt er die Mehrkosten."
    }
    p {
      +"Die Lieferfrist verlängert sich angemessen bei Maßnahmen im Rahmen von Arbeitskämpfen, insbesondere Streik und Aussperrung, sowie beim Eintritt unvorhergesehener Hindernisse, wie etwa Betriebsstörungen, Rohstoffmängel, Verkehrsstörungen, Lieferverzögerungen der Vorlieferanten, etc., die außerhalb des Willens von Lizergy liegen, soweit solche Hindernisse nachweislich auf die Fertigstellung oder Ablieferung des Liefergegenstandes von erheblichem Einfluss sind."
    }
    p {
      +"Dies gilt auch, wenn die Umstände bei Vor-oder Unterlieferern eintreten. Die vorbezeichneten Umstände sind auch dann von Lizergy nicht zu vertreten, wenn sie während eines bereits vorliegenden Verzuges entstehen. Die Einhaltung der Lieferfrist setzt die Erfüllung der Vertragspflichten des Bestellers voraus. Ansonsten verlängern sich Lieferzeit und Lieferfrist angemessen."
      br {}
      +"Wir liefern nicht an Packstationen."
    }

    h5("mt-4") {
      +"9. Liefervorbehalt"
    }
    p {
      +"Der Vertragsschluss erfolgt unter dem Vorbehalt, dass Lizergy im Falle nicht ordnungsgemäßer Selbstbelieferung mit der bestellten Ware, nicht oder nur teilweise an den Kunden zu leisten hat. Dies gilt nur für den Fall, dass die nicht ordnungsgemäße Lieferung nicht von Lizergy zu vertreten ist. Im Falle der Nichtverfügbarkeit oder der nur teilweisen Verfügbarkeit der Ware wird der Kunde unverzüglich informiert. Eine hierauf bezogene und bereits erbrachte Gegenleistung des Kunden wird unverzüglich zurückerstattet."
    }

    h5("mt-4") {
      +"10. Transportschäden"
    }
    h6 {
      +"Für Verbraucher gilt:"
    }
    p {
      +"Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können."
    }
    h6 {
      +"Für Unternehmer gilt:"
    }
    p {
      +"Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung geht auf Sie über, sobald wir die Sache dem Spediteur, dem Frachtführer oder der sonst zur Ausführung der Versendung bestimmten Person oder Anstalt ausgeliefert haben. Unter Kaufleuten gilt die in § 377 HGB geregelte Untersuchungs-und Rügepflicht. Unterlassen Sie die dort geregelte Anzeige, so gilt die Ware als genehmigt, es sei denn, dass es sich um einen Mangel handelt, der bei der Untersuchung nicht erkennbar war. Dies gilt nicht, falls wir einen Mangel arglistig verschwiegen haben."
    }

    h5("mt-4") {
      +"11. Gewährleistung und Garantien"
    }
    p {
      +"Soweit nicht nachstehend ausdrücklich anders vereinbart, gilt das gesetzliche Mängelhaftungsrecht."
    }
    p {
      +"Beim Kauf gebrauchter Waren durch Verbraucher gilt: wenn der Mangel nach Ablauf eines Jahres ab Ablieferung der Ware auftritt, sind die Mängelansprüche ausgeschlossen. Mängel, die innerhalb eines Jahres ab Ablieferung der Ware auftreten, können im Rahmen der gesetzlichen Verjährungsfrist von zwei Jahren ab Ablieferung der Ware geltend gemacht werden."
    }
    p {
      +"Für Unternehmer beträgt die Verjährungsfrist für Mängelansprüche bei neu hergestellten Sachen ein Jahr ab Gefahrübergang. Der Verkauf gebrauchter Waren erfolgt unter Ausschluss jeglicher Gewährleistung. Die gesetzlichen Verjährungsfristen für den Rückgriffsanspruch nach § 445a BGB bleiben unberührt. Gegenüber Unternehmern gelten als Vereinbarung über die Beschaffenheit der Ware nur unsere eigenen Angaben und die Produktbeschreibungen des Herstellers, die in den Vertrag einbezogen wurden; für öffentliche Äußerungen des Herstellers oder sonstige Werbeaussagen übernehmen wir keine Haftung. Ist die gelieferte Sache mangelhaft, leisten wir gegenüber Unternehmern zunächst nach unserer Wahl Gewähr durch Beseitigung des Mangels (Nachbesserung) oder durch Lieferung einer mangelfreien Sache (Ersatzlieferung). Die vorstehenden Einschränkungen und Fristverkürzungen gelten nicht für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden"
    }
    div {
      ul {
        li { +"bei Verletzung des Lebens, des Körpers oder der Gesundheit" }
        li { +"bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung sowie Arglist" }
        li { +"bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf (Kardinalpflichten)" }
        li { +"im Rahmen eines Garantieversprechens, soweit vereinbart" }
        li { +"soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist." }
      }
    }
    p {
      +"Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Onlineshop."
    }

    p {
      +"Kundendienst: Sie erreichen unseren Kundendienst für Fragen, Reklamationen und Beanstandungen werktags von Montag bis Donnerstag von 9 bis 16:30 Uhr und Freitag von 9 bis 15 Uhr unter der Telefonnummer +49&nbsp;7441/9521900 sowie per E-Mail unter "
      a(href = "mailto:info@lizergy.com") {
        +"info@lizergy.com"
      }
    }

    h5("mt-4") {
      +"12. Haftung"
    }
    p {
      +"Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt"
    }
    div {
      ul {
        li { +"bei Verletzung des Lebens, des Körpers oder der Gesundheit," }
        li { +"bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung," }
        li { +"bei Garantieversprechen, soweit vereinbart, oder" }
        li { +"soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist." }
      }
    }
    p {
      +"Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf Schadensersatz ausgeschlossen."
    }

    h5("mt-4") {
      +"13. Preise"
    }
    p {
      +"Alle im Onlineshop aufgeführten Preise sind inklusive der aktuellen Mehrwertsteuer in Deutschland. Ausgenommen davon sind Preise für Wiederverkäufer und gewerbliche Kunden im geschlossenen Bereich, die nach Freischaltung zur Verfügung stehen, und sich netto zuzüglich der aktuellen Mehrwertsteuer verstehen."
    }

    h5("mt-4") {
      +"14. Zurückbehaltung"
    }
    p {
      +"Sie können ein Zurückbehaltungsrecht nur ausüben, soweit die Ansprüche aus dem gleichen Vertragsverhältnis resultieren."
    }

    h5("mt-4") {
      +"15. Kennzeichen"
    }
    p {
      +"Der Kunde ist verpflichtet, Kennzeichen und/oder Hinweise des/der Hersteller zu beachten, welche auf den Produkten aufgebracht sind. Dies gilt insbesondere für Marken."
    }

    h5("mt-4") {
      +"16. Technische Beratung"
    }
    p {
      +"Sämtliche anwendungstechnische Beratungen von Lizergy in Wort, Schrift oder durch Versuche außerhalb einer gesonderten Vereinbarung zur Beratung (vergütungspflichtiger Beratungsvertrag), erfolgt nach bestem Wissen, gilt jedoch nur als unverbindlicher Hinweis, auch in Bezug auf etwaige Schutzrechte Dritter, und befreit den Kunden nicht von der eigenen Prüfung der von Lizergy gelieferten Produkte auf ihre Eignung für die beabsichtigten Verfahren und Zwecke. Anwendung, Verwendung und Verarbeitung der Produkte erfolgen außerhalb der Kontrollmöglichkeiten von Lizergy und liegen im ausschließlichen Verantwortungsbereich des Kunden."
    }

    h5("mt-4") {
      +"17. Schlussbestimmungen"
    }
    p {
      +"Diese Vereinbarung sowie alle mit den Kunden entstehenden Verträge und/oder Rechtsverhältnisse unterliegen ausschließlich deutschem Recht unter Ausschluss des UN-Kaufrechts (CISG)."
    }
    p {
      +"Sind Sie Kaufmann im Sinne des Handelsgesetzbuches, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen uns und Ihnen unser Geschäftssitz."
    }
    p {
      +"Lizergy GmbH"
      br { }
      +"Jaspisstrasse 13, 72250 Freudenstadt"
      br { }
      +"Allgemeine Geschäftsbedingungen"
      br { }
      +"(Stand 07.11.2021)"
    }
  }
}


external interface AGBModalProps : Props
