package services.http

import io.ktor.client.request.*
import it.neckar.common.featureflags.FeatureFlags
import it.neckar.common.featureflags.FeatureFlagsSupport

/**
 * Adds the header containing the feature flags
 */
fun HttpRequestBuilder.featureFlagsHeader() {
  header(FeatureFlags.HeaderKey, FeatureFlagsSupport.flags.encodeToString())
}
