import components.schnellbestellung.forms.inputs.FeedbackInputs
import components.schnellbestellung.forms.inputs.InputFieldData
import components.schnellbestellung.forms.inputs.inputAreaField
import components.schnellbestellung.forms.inputs.inputFieldDataAndLabel
import de.wunschsolar.model.Feedback
import de.wunschsolar.model.Feedback.EmojiFeedback.Happy
import de.wunschsolar.model.Feedback.EmojiFeedback.Neutral
import de.wunschsolar.model.Feedback.EmojiFeedback.Sad
import de.wunschsolar.model.Feedback.EmojiFeedback.SlightlyHappy
import de.wunschsolar.model.Feedback.EmojiFeedback.SlightlySad
import it.neckar.react.common.*
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import kotlinx.html.role
import org.w3c.dom.CENTER
import org.w3c.dom.ScrollIntoViewOptions
import org.w3c.dom.ScrollLogicalPosition
import react.*
import react.dom.*
import services.UiActions
import styled.*


fun RBuilder.feedback(): Unit = child(feedback) {}

val feedback: FC<FeedbackProps> = fc("feedback") {
  val emojiFeedback = useState(Feedback.EmojiFeedback.None)
  val feedbackFieldData = InputFieldData("feedbackForm")

  val feedbackEmail = InputFieldData(
    id = "feedbackEmail",
    inputType = InputType.email,
    required = false,
  )
  val feedbackPhone = InputFieldData(
    id = "feedbackPhone",
    required = false,
  )

  val feedbackInputs = useMemo(emojiFeedback.value, feedbackFieldData, feedbackEmail, feedbackPhone) {
    FeedbackInputs(
      emojiFeedback = emojiFeedback.value,
      feedbackFieldData = feedbackFieldData,
      feedbackEmail = feedbackEmail,
      feedbackPhone = feedbackPhone,
    )
  }


  div(classes = "bg-secondary py-4") {
    styledDiv {
      attrs {
        classes = setOf("container", "p-4")
      }
      css {
        maxWidth = maxSiteWidth
      }

      h4 {
        +"Nicht gefunden, was Sie suchen?"
      }

      div("mb-3") {
        p {
          +"Wir arbeiten gerade daran unser Angebot für alle Selbermacher zu optimieren."
          br {}
          +"Ihr Feedback würde uns bei dieser Mission sehr helfen."
        }
      }

      div("mb-3") {
        p {
          strong {
            +"Wie zufrieden sind Sie mit dem Angebot auf dieser Website?"
          }
        }

        div("btn-group") {
          attrs {
            role = "group"
          }

          input(classes = "btn-check", type = InputType.radio, name = "feedbackRadioSad") {
            attrs {
              id = "feedbackRadioSad"
              autoComplete = false
              onClickFunction = {
                emojiFeedback.setter(Sad)
              }
              checked = emojiFeedback.value == Sad
            }
          }
          label(classes = "btn btn-outline-primary") {
            attrs {
              htmlForFixed = "feedbackRadioSad"
            }
            img(src = "img/emojis/sad-emoji.png") {
              attrs {
                height = "40"
              }
            }
          }
          input(classes = "btn-check", type = InputType.radio, name = "feedbackRadioSlightlySad") {
            attrs {
              id = "feedbackRadioSlightlySad"
              autoComplete = false
              onClickFunction = {
                emojiFeedback.setter(SlightlySad)
              }
              checked = emojiFeedback.value == SlightlySad
            }
          }
          label(classes = "btn btn-outline-primary") {
            attrs {
              htmlForFixed = "feedbackRadioSlightlySad"
            }
            img(src = "img/emojis/slightly-sad-emoji.png") {
              attrs {
                height = "40"
              }
            }
          }
          input(classes = "btn-check", type = InputType.radio, name = "feedbackRadioNeutral") {
            attrs {
              id = "feedbackRadioNeutral"
              autoComplete = false
              onClickFunction = {
                emojiFeedback.setter(Neutral)
              }
              checked = emojiFeedback.value == Neutral
            }
          }
          label(classes = "btn btn-outline-primary") {
            attrs {
              htmlForFixed = "feedbackRadioNeutral"
            }
            img(src = "img/emojis/neutral-emoji.png") {
              attrs {
                height = "40"
              }
            }
          }
          input(classes = "btn-check", type = InputType.radio, name = "feedbackRadioSlightlyHappy") {
            attrs {
              id = "feedbackRadioSlightlyHappy"
              autoComplete = false
              onClickFunction = {
                emojiFeedback.setter(SlightlyHappy)
              }
              checked = emojiFeedback.value == SlightlyHappy
            }
          }
          label(classes = "btn btn-outline-primary") {
            attrs {
              htmlForFixed = "feedbackRadioSlightlyHappy"
            }
            img(src = "img/emojis/slightly-happy-emoji.png") {
              attrs {
                height = "40"
              }
            }
          }
          input(classes = "btn-check", type = InputType.radio, name = "feedbackRadioHappy") {
            attrs {
              id = "feedbackRadioHappy"
              autoComplete = false
              onClickFunction = {
                emojiFeedback.setter(Happy)
              }
              checked = emojiFeedback.value == Happy
            }
          }
          label(classes = "btn btn-outline-primary") {
            attrs {
              htmlForFixed = "feedbackRadioHappy"
            }
            img(src = "img/emojis/happy-emoji.png") {
              attrs {
                height = "40"
              }
            }
          }
        }
      }

      div("mb-3") {
        p("mb-2") {
          strong {
            +"Gibt es etwas, das wir verbessern können?"
          }
        }

        inputAreaField(
          inputFieldData = feedbackInputs.feedbackFieldData,
          placeholder = "Schreiben Sie hier ihr Feedback...",
        )
      }

      div(classes = "row gy-3 mb-4") {
        div(classes = "col-12 col-md-6") {
          inputFieldDataAndLabel(
            inputFieldData = feedbackInputs.feedbackEmail,
            fieldName = "Email",
            invalidFeedback = "Bitte eine Email eintragen!",
          )
        }

        div(classes = "col-12 col-md-6") {
          inputFieldDataAndLabel(
            inputFieldData = feedbackInputs.feedbackPhone,
            fieldName = "Telefon",
            invalidFeedback = "Bitte eine Telefonnummer eintragen!",
          )
        }
      }

      val formIsValid = feedbackInputs.isValid()

      div {
        a(classes = "col-12 btn btn-dark shadow-none") {
          attrs {
            onClickFunction = useCallback(feedbackInputs) {
              feedbackInputs.validateAllFields()

              if (formIsValid == null) {
                val newFeedback = feedbackInputs.toFeedback()
                UiActions.feedback(newFeedback)
              } else {
                document.getElementById(formIsValid)?.scrollIntoView(
                  ScrollIntoViewOptions(
                    block = ScrollLogicalPosition.CENTER,
                    inline = ScrollLogicalPosition.CENTER
                  )
                )
              }
            }

            if (formIsValid == null) {
              attrs["data-bs-toggle"] = "modal"
              attrs["data-bs-target"] = "#feedbackConfirmationModal"
            }
          }

          +"Feedback absenden"
        }
      }
    }
  }
}


external interface FeedbackProps : Props
