package components.schnellbestellung.forms.inputs

import it.neckar.react.common.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.*


fun RBuilder.inputAreaField(
  /**
   * The value and setter for the value.
   * Must be created with a useState hook
   */
  inputFieldData: InputFieldData,

  /**
   * The input type for the input field
   * Text is the default
   */
  inputType: InputType = InputType.text,

  /**
   * Placeholder text to be displayed when the text area is empty
   */
  placeholder: String? = null,

  /**
   * If the field is required for the form
   */
  required: Boolean = true,

  /**
   * The feedback given to the user of the input is valid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  validFeedback: String? = null,

  /**
   * The feedback given to the user of the input is invalid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  invalidFeedback: String? = null,

  ): Unit = child(inputAreaField) {
  attrs {
    this.inputFieldData = inputFieldData
    this.inputType = inputType
    this.placeholder = placeholder
    this.required = required
    this.validFeedback = validFeedback
    this.invalidFeedback = invalidFeedback
  }
}

val inputAreaField: FC<InputAreaFieldProps> = fc("inputAreaField") { props ->
  val inputFieldData = props.inputFieldData
  val fieldValue = inputFieldData.textState.value
  val fieldSetter = inputFieldData.textState.setter

  val fieldRequired = props.required


  textarea(classes = "form-control") {
    attrs {
      id = inputFieldData.id
      value = fieldValue.orEmpty()
      props.placeholder?.let {
        placeholder = it
      }

      onChangeFunction = useCallback(fieldSetter) { event ->
        val inputElement = event.target as HTMLTextAreaElement
        fieldSetter(inputElement.value)
      }
      required = fieldRequired
    }

    validationStyle {
      inputFieldData.calculateValidationState()
    }
  }

  props.validFeedback?.let {
    div("valid-feedback") {
      +it
    }
  }

  props.invalidFeedback?.let {
    div("invalid-feedback") {
      +it
    }
  }
}

external interface InputAreaFieldProps : Props {
  /**
   * Is called on change
   */
  var inputFieldData: InputFieldData

  /**
   * The input type for the input field
   */
  var inputType: InputType

  /**
   * Placeholder text to be displayed when the text area is empty
   */
  var placeholder: String?

  /**
   * If the field is required for the form
   */
  var required: Boolean

  /**
   * The feedback given to the user of the input is valid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  var validFeedback: String?

  /**
   * The feedback given to the user of the input is invalid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  var invalidFeedback: String?
}
