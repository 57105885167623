package it.neckar.projects.common

import kotlin.jvm.JvmInline

/**
 * Represents a port
 */
@JvmInline
value class Port(val value: Int) {
  override fun toString(): String {
    return value.toString()
  }

  //Required for extension methods
  companion object {
    /**
     * Helper method for refactorings
     */
    @Deprecated("Use the constructor instead", ReplaceWith("port"))
    operator fun invoke(port: Port): Port {
      return port
    }
  }

}
