package redux.actions

import redux.FeedbackState
import redux.WunschsolarAppState


/**
 * Updates the order state
 */
class UpdateFeedbackStateAction(val newFeedbackState: FeedbackState) : StateAction {
  override fun WunschsolarAppState.updateState(): WunschsolarAppState {
    return this.copy(feedbackState = this@UpdateFeedbackStateAction.newFeedbackState)
  }
}
