package de.wunschsolar.model.products

import it.neckar.open.kotlin.lang.percent
import it.neckar.open.unit.currency.EUR
import de.wunschsolar.model.products.Product.ProductId
import kotlinx.serialization.Serializable

/**
 * Represents a bundle to be displayed in the store
 */
@Serializable
data class Bundle(
  /**
   * [ProductId] of the [Bundle]
   */
  override val id: ProductId,

  /**
   * Displayed at the top of the overview page
   */
  override val description: String,
  /**
   * Shortened name, displayed in the simplified overview before checkout
   */
  val shortName: String,

  /**
   * Path to the image showing the entire package at once
   */
  override val imagePath: String,

  /**
   * Short header to be displayed before listing the [Bundle]'s [content]
   */
  val contentHeader: String,
  /**
   * List of [ProductComponentConfiguration]s representing the [Bundle]'s content
   */
  val content: List<ProductComponentConfiguration>,

  /**
   * List of optional parts or services that may also be ordered but must be specified explicitly
   */
  val optionalContent: List<String>? = null,
  /**
   * List if compatibility required for the [Bundle] to be installed
   */
  val compatability: List<String>? = null,

  /**
   * Optional, additional markup for this [Bundle]
   */
  val additionalMarkup: @EUR Double = 0.00,

  val paypalMarkup: Double = 2.5.percent,

  val payInAdvanceDiscount: Double = 2.0.percent,

  ) : Product {

  /**
   * List of all images connected to this [Bundle] for the carousel
   */
  val images: List<String> = listOf(imagePath) + content.mapNotNull { it.imagePath }

  /**
   * Price with the markup for each component already included
   * Do not display this property unless you 100% mean to
   * Instead, almost always display [bundlePrice] instead
   */
  override val price: @EUR Double = content.sumOf { it.priceWithMarkup } + additionalMarkup

  /**
   * Price for the [Bundle] including VAT
   */
  val priceWithVAT: @EUR Double = price + price.calculateValueAddedTax()

  /**
   * Price for the [Bundle] including VAT and the [paypalMarkup]
   * This is the price most relevant to be displayed
   */
  val bundlePrice: @EUR Double = priceWithVAT * (1.0 + paypalMarkup)

  /**
   * Discount to be applied based on the [PaymentMethod]
   */
  fun paymentMethodDiscount(paymentMethod: PaymentMethod): @EUR Double? {
    return when (paymentMethod) {
      PaymentMethod.Vorkasse -> bundlePrice * payInAdvanceDiscount
      PaymentMethod.PayPal -> null
    }
  }
}

fun Double.calculateValueAddedTax(): @EUR Double {
  return this * 19.percent
}

enum class PaymentMethod(val discountName: String) {
  Vorkasse("Vorkasse Rabatt"),
  PayPal(""),
}
