package redux

import de.wunschsolar.model.ValidatedDiscountCode

/**
 * The app state
 */
data class WunschsolarAppState(
  /**
   * The order state
   */
  val orderState: OrderState = OrderState.EnteringData,

  /**
   * The currently applied discount code
   */
  val appliedDiscount: ValidatedDiscountCode? = null,

  val feedbackState: FeedbackState = FeedbackState.EnteringData,

  )
