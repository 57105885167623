import it.neckar.open.collections.fastForEach
import it.neckar.open.time.nowMillis
import it.neckar.react.common.*
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import styled.*
import kotlin.js.Date


fun RBuilder.wunschsolarFooter(): Unit = child(wunschsolarFooter) {}

val wunschsolarFooter: FC<WunschsolarFooterProps> = fc("wunschsolarFooter") {

  styledDiv {
    attrs {
      classes = setOf("container", "py-5", "text-center")
    }
    css {
      maxWidth = maxSiteWidth
    }

    p {
      a(classes = "link-dark", href = "./") {
        attrs["data-bs-toggle"] = "modal"
        attrs["data-bs-target"] = "#datenschutzerklaerung"
        +"Datenschutzerklärung"
      }

      span("px-3") {
        +"·"
      }

      a(classes = "link-dark", href = "./") {
        attrs["data-bs-toggle"] = "modal"
        attrs["data-bs-target"] = "#impressum"
        +"Impressum"
      }
    }
  }


  val cookiesAcceptedName = "cookiesAccepted"
  val acceptedCookies = useState(getCookie(cookiesAcceptedName).isEmpty())
  val reactedToCookieBanner = useState(false)

  if (acceptedCookies.value && !reactedToCookieBanner.value) {
    notificationBanner("Cookies", BannerType.Info, BannerPlacement.Bottom) {
      p {
        +"Wir nutzen Cookies um unsere Website betreiben zu können und die Nutzererfahrung zu verbessern."

        br {}

        a(classes = "link-dark", href = "./") {
          attrs["data-bs-toggle"] = "modal"
          attrs["data-bs-target"] = "#datenschutzerklaerung"
          +"Weitere Informationen"
        }
      }

      div("mt-3") {
        button(classes = "btn btn-primary me-3") {
          attrs {
            onClickFunction = {
              reactedToCookieBanner.setter(true)
              acceptedCookies.setter(true)
              setCookie(cookiesAcceptedName, "true", 356)
            }
          }

          +"Akzeptieren"
        }
        button(classes = "btn btn-secondary") {
          attrs {
            onClickFunction = {
              reactedToCookieBanner.setter(true)
            }
          }

          +"Ablehnen"
        }
      }
    }
  }
}

fun setCookie(cookieName: String, cookieValue: String, expiresAfterDays: Int) {
  val date = Date(nowMillis() + (expiresAfterDays * 24 * 60 * 60 * 1000))
  val expires = "expires=${date.toUTCString()}"
  document.cookie = "$cookieName=$cookieValue; $expires; path=/"
}

fun getCookie(cookieName: String): String {
  document.cookie.split(';').fastForEach { it: String ->
    it.trim().let {
      "$cookieName=".let { name ->
        if (it.indexOf(name) == 0) {
          return it.substring(name.length, it.length)
        }
      }
    }
  }

  return ""
}


external interface WunschsolarFooterProps : Props
