import it.neckar.open.formatting.formatEuro
import it.neckar.open.i18n.I18nConfiguration
import components.bundleRoutes
import components.schnellbestellung.schnellbestellungKomplettpaketDetails
import web.cssom.ClassName
import de.wunschsolar.model.products.AvailableBundles
import de.wunschsolar.model.products.Bundle
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import react.router.dom.*
import redux.WunschsolarAppState
import styled.*


fun RBuilder.packageOverview(
  appState: WunschsolarAppState,
): Unit = child(packageOverview) {
  attrs {
    this.appState = appState
  }
}

val packageOverview: FC<PackageOverviewProps> = fc("packageOverview") { props ->

  styledDiv {
    attrs {
      classes = setOf("container", "p-4")
    }
    css {
      maxWidth = maxSiteWidth
    }

    div("mb-4") {
      h2 {
        +"PV-Komplettpakete für Selbermacher"
      }
    }

    div(classes = "row row-cols-1 row-cols-md-2 g-4") {

      //packageCard(AvailableBundles.wallboxABL1w1108emh1Bundle)
      //packageCard(AvailableBundles.wallboxSMAEVcharger22Bundle)

      packageCard(AvailableBundles.complete3KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete3KWpBundle)

      packageCard(AvailableBundles.complete4_5KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete4_5KWpBundle)

      packageCard(AvailableBundles.complete6KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete6KWpBundle)

      packageCard(AvailableBundles.complete6_75KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete6_75KWpBundle)

      packageCard(AvailableBundles.complete8_25KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete8_25KWpBundle)

      packageCard(AvailableBundles.complete9_9KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete9_9KWpBundle)

      packageCard(AvailableBundles.complete12KWpWithoutBatteryBundle)
      packageCard(AvailableBundles.complete12KWpBundle)
    }
  }
}

fun RBuilder.packageCard(bundle: Bundle) {
  div(classes = "col") {
    div(classes = "card border-primary") {
      attrs {
        onClickFunction = {
          schnellbestellungKomplettpaketDetails(bundle)
        }
      }

      div("card-body") {
        styledH5 {
          css {
            classes.add("card-title")
            height = 40.px
          }

          +bundle.description
        }

        img(src = bundle.imagePath, classes = "img-fluid rounded my-3", alt = "...") {}

        div("card-text") {
          h6(classes = "mb-0") {
            +"${bundle.bundlePrice.formatEuro(I18nConfiguration.Germany)}*"
          }
          p {
            +"* Preise inkl. MwSt."
          }
        }

        Link {
          div("d-grid") {
            button(classes = "btn btn-primary stretched-link", type = ButtonType.button) {
              +"Zur Schnellbestellung"
            }
          }

          attrs {
            to = (bundleRoutes[bundle] ?: return@attrs)
            className = ClassName("nav-link link-dark px-2 active")
          }
        }
      }
    }
  }
}


external interface PackageOverviewProps : Props {
  var appState: WunschsolarAppState
}
