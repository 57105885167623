package redux

/**
 * The order date
 */
enum class OrderState {
  /**
   * No server communication yet - the user is entering the data
   */
  EnteringData,

  /**
   * The order has been sent to the server, waiting for response
   */
  OrderSent,

  /**
   * The order has been processed by the server
   */
  OrderProcessed,

  /**
   * The order has failed - the server has returned an error or not answered at all
   */
  OrderFailed,
}
