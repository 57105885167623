package components.schnellbestellung.forms.inputs

import it.neckar.open.kotlin.lang.nullIfBlank
import de.wunschsolar.model.Feedback
import de.wunschsolar.model.Message
import de.wunschsolar.model.Order
import de.wunschsolar.model.Seriousness
import services.Server

/**
 * [FeedbackInputs] holds all the [InputFieldData] for the form inputs
 */
data class FeedbackInputs(
  val emojiFeedback: Feedback.EmojiFeedback,
  val feedbackFieldData: InputFieldData,
  val feedbackEmail: InputFieldData,
  val feedbackPhone: InputFieldData,
) : ReferencedUserInputs {

  /**
   * Returns null if all fields are valid
   * or th id to the first input field that is not valid
   */
  override fun isValid(): String? {
    feedbackFieldData.let {
      if (!it.isValid()) return it.id
    }

    return null
  }

  /**
   * This method is called when the submit button is pressed
   * It sets all values which are null to empty strings
   */
  override fun validateAllFields() {
    feedbackFieldData.reset()
  }

  /**
   * Return an [Order] with all relevant values
   */
  fun toFeedback(): Feedback {
    return Feedback(
      id = Message.MessageId.random(),
      emoji = emojiFeedback,
      message = feedbackFieldData.value!!,
      email = feedbackEmail.value.nullIfBlank(),
      phone = feedbackPhone.value.nullIfBlank(),
      seriousness = when (Server.instance) {
        Server.File -> Seriousness.JustTesting
        Server.Localhost -> Seriousness.JustTesting
        Server.Spielwiese -> Seriousness.JustTesting
        Server.Schaufenster -> Seriousness.JustTesting
        Server.Production -> Seriousness.Production
      },
    )
  }

}
