package components.schnellbestellung

import it.neckar.open.formatting.formatEuro
import it.neckar.open.i18n.I18nConfiguration
import de.wunschsolar.model.products.Bundle
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.role
import maxSiteWidth
import react.*
import react.dom.*
import styled.*


/**
 * A summary of all the order information
 * and the button to confirm the order
 */
fun RBuilder.schnellbestellungOverview(
  bundle: Bundle,
): Unit = child(schnellbestellungOverview) {
  attrs {
    this.bundle = bundle
  }
}

val schnellbestellungOverview: FC<SchnellbestellungOverviewProps> = fc("schnellbestellungOverview") { props ->

  val bundle = props.bundle


  styledDiv {
    attrs {
      classes = setOf("container", "px-4")
    }
    css {
      maxWidth = maxSiteWidth
    }

    div(classes = "row mt-3 gy-3") {
      h2(classes = "col-12 mb-1") {
        +bundle.description
      }
      h4(classes = "col-12 mt-0 mb-4") {
        +"zur Selbstmontage"
      }

      carousel(
        name = "overview",
        images = bundle.images,
      )
    }

    div(classes = "container mt-2 px-0") {
      h5(classes = "mb-0") {
        +"${bundle.bundlePrice.formatEuro(I18nConfiguration.Germany)}*"
      }
      p {
        +"* Preise inkl. MwSt."
      }

      a(classes = "col-12 btn btn-dark mt-1 shadow-none", href = "#bestellen") {
        attrs {
          role = "button"
        }
        +"Bestellen"
      }
    }
  }
}


external interface SchnellbestellungOverviewProps : Props {
  var bundle: Bundle
}
