package components.modals

import it.neckar.react.common.*
import react.*
import react.dom.*


/**
 * The confirmation modal to be displayed when the submit button is pressed and all field inputs are valid
 */
fun RBuilder.impressumModal(): Unit = child(impressumModal) {}

val impressumModal: FC<ImpressumModalProps> = fc("impressumModal") {
  if (true) {
    modal(
      id = "impressum",
      title = "Impressum",
      modalCentered = true,
    ) {

      p {
        strong { +"Neckar IT GmbH" }
        br {}
        +"Ulrichstraße 25"
        br {}
        +"72116 Mössingen"
        br {}
        +"Deutschland"
        br {}
        strong { +"Telefon 07473 95949-61" }
        a(href = "mailto:info@neckar.it") {
          +"info@neckar.it"
        }
      }

      p {
        strong { +" Gemeinsam vertretungsberechtigte Geschäftsführer:" }
        br {}
        +"Johannes Schneider, Christian Erbelding"
      }

      p {
        strong { +"Umsatzsteueridentifikationsnummer:" }
        +" DE326321633"
        br {}
        strong { +"Registergericht:" }
        +" Amtsgericht Stuttgart"
        br {}
        strong { +"Registernummer:" }
        +" HRB 770878"
      }


    }

    return@fc
  }

  modal(
    id = "impressum",
    title = "Impressum",
    modalCentered = true,
  ) {

    p {
      strong { +"Lizergy GmbH" }
      br {}
      +"Jaspisstrasse 13"
      br {}
      +"72250 Freudenstadt"
      br {}
      +"Deutschland"
      br {}
      strong { +"Telefon 07441 95219-00" }
      br {}
      +"Telefax 07441 9521909"
      br {}
      a(href = "mailto:info@lizergy.com") {
        +"info@lizergy.com"
      }
      br {}
      a(href = "https://www.lizergy.com") {
        +"lizergy.com"
      }
    }

    p {
      +"Notfalltelefon: 07441 95219-08"
      br {}
      em {
        +"Bitte nutzen Sie die Notfallnummer nur im äußersten NOTFALL."
        br {}
        +"Wir sind über diese Telefonnummer am Wochenende erreichbar."
        br {}
        +"Alle Notfalleinsätze werden mit einem 100%-Aufschlag der Arbeitszeit und Anfahrt berechnet."
      }
    }

    p {
      strong { +"Vertretungsberechtigter Geschäftsführer:" }
      br {}
      +"Simon Hänel"
    }

    p {
      strong { +"Umsatzsteueridentifikationsnummer:" }
      +" DE290865644"
      br {}
      strong { +"Registergericht:" }
      +" Amtsgericht Stuttgart"
      br {}
      strong { +"Registernummer:" }
      +" HRB 745905"
    }

    p { strong { +"Streitschlichtung" } }
    p {
      +"Die Europäische Kommission stellt eine Plattform zur Online- Streitbeilegung (OS) bereit: "
      a(href = "https://ec.europa.eu/consumers/odr") { +"https://ec.europa.eu/consumers/odr" }
      +"."
      br {}
      +"Unsere E-Mail-Adresse finden Sie oben im Impressum."
    }
    p { +"Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen." }

    p { strong { +"Haftung für Inhalte" } }
    p { +"Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen." }
    p { +"Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen." }

    p { strong { +"Haftung für Links" } }
    p { +"Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar." }
    p { +"Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen." }

    p { strong { +"Urheberrecht" } }
    p { +"Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet." }
    p { +"Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen." }

    p { strong { +"Konzeption, Gestaltung und Umsetzung" } }
    p {
      a(href = "https://neckar.it/", target = "_blank") {
        attrs {
          rel = "noopener noreferrer"
        }
        +"Neckar IT"
      }
    }
  }
}


external interface ImpressumModalProps : Props
