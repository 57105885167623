@file:UseSerializers(UuidSerializer::class)

package de.wunschsolar.model

import com.benasher44.uuid.Uuid
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

/**
 * Represents a wunschsolar.de message
 */
interface Message {
  val id: MessageId
  val seriousness: Seriousness

  /**
   * Format this order into human-readable HTML to be put into an email
   */
  fun html(): String

  /**
   * An id for a message
   */
  @Serializable
  data class MessageId(val uuid: Uuid) {
    override fun toString(): String {
      return uuid.toString()
    }

    fun format(): String {
      return uuid.toString()
    }

    companion object {
      fun random(): MessageId {
        return MessageId(randomUuid4())
      }
    }
  }
}

enum class Seriousness {
  Production,
  JustTesting
}
