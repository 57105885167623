package it.neckar.user

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UserLoginNameSerializer : KSerializer<UserLoginName> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("UserLoginName", PrimitiveKind.STRING)

  override fun serialize(encoder: Encoder, value: UserLoginName) {
    encoder.encodeString(value.value)
  }

  override fun deserialize(decoder: Decoder): UserLoginName {
    return UserLoginName(decoder.decodeString())
  }
}

