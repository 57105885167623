package components.schnellbestellung.forms.inputs

import de.wunschsolar.model.AlreadyValidatedDiscountCode
import de.wunschsolar.model.Order
import de.wunschsolar.model.Order.OrderAddress
import de.wunschsolar.model.Seriousness
import de.wunschsolar.model.SuccessfullyValidatedDiscountCode
import de.wunschsolar.model.UnsuccessfullyValidatedDiscountCode
import de.wunschsolar.model.products.Bundle
import de.wunschsolar.model.products.PaymentMethod
import it.neckar.react.common.*
import kotlinx.html.InputType
import react.*
import redux.WunschsolarAppState
import services.Server

/**
 * [CustomerInformationInputs] holds all the [InputFieldData] for the form inputs
 */
data class CustomerInformationInputs(
  val appState: WunschsolarAppState,
) : ReferencedUserInputs {
  private val zipCodeRegex = Regex("[0-9]{5}")
  private val zipValidation = { zipCode: String? ->
    zipCode?.let {
      if (zipCodeRegex.matches(zipCode)) {
        ValidationState.Valid
      } else {
        ValidationState.Invalid
      }
    } ?: ValidationState.NotValidated
  }

  val firstNameBilling: InputFieldData = InputFieldData("firstNameBilling")
  val lastNameBilling: InputFieldData = InputFieldData("lastNameBilling")
  val streetBilling: InputFieldData = InputFieldData("streetBilling")
  val cityBilling: InputFieldData = InputFieldData("cityBilling")
  val zipBilling: InputFieldData = InputFieldData(
    id = "zipBilling",
    validationFunction = zipValidation,
  )

  val contactEmail: InputFieldData = InputFieldData(
    id = "contactEmail",
    inputType = InputType.email,
  )
  val contactPhone: InputFieldData = InputFieldData("contactPhone")

  val uebernehmeLieferadresse: StateInstance<Boolean> = useState(true)

  val firstNameShipping: InputFieldData = InputFieldData("firstNameShipping")
  val lastNameShipping: InputFieldData = InputFieldData("lastNameShipping")
  val streetShipping: InputFieldData = InputFieldData("streetShipping")
  val cityShipping: InputFieldData = InputFieldData("cityShipping")
  val zipShipping: InputFieldData = InputFieldData(
    "zipShipping",
    validationFunction = zipValidation,
  )

  val billingFields: List<InputFieldData> = listOf(firstNameBilling, lastNameBilling, streetBilling, cityBilling, zipBilling)
  val contactFields: List<InputFieldData> = listOf(contactEmail, contactPhone)
  val shippingFields: List<InputFieldData> = listOf(firstNameShipping, lastNameShipping, streetShipping, cityShipping, zipShipping)

  val additionalNotes: InputFieldData = InputFieldData(
    id = "additionalNotes",
    required = false,
  )

  val paymentMethod: StateInstance<PaymentMethod> = useState(PaymentMethod.Vorkasse)

  val discountCode: InputFieldData = InputFieldData(
    id = "discountCode",
    required = false,
  ) {
    when (appState.appliedDiscount) {
      is SuccessfullyValidatedDiscountCode -> ValidationState.Valid
      is UnsuccessfullyValidatedDiscountCode, is AlreadyValidatedDiscountCode -> ValidationState.Invalid
      null -> ValidationState.NotValidated
    }
  }


  /**
   * Returns null if all fields are valid
   * or th id to the first input field that is not valid
   */
  override fun isValid(): String? {
    billingFields.forEach {
      if (!it.isValid()) return it.id
    }
    contactFields.forEach {
      if (!it.isValid()) return it.id
    }
    if (!uebernehmeLieferadresse.value) {
      shippingFields.forEach {
        if (!it.isValid()) return it.id
      }
    }

    return null
  }

  /**
   * This method is called when the submit button is pressed
   * It sets all values which are null to empty strings
   */
  override fun validateAllFields() {
    billingFields.forEach {
      it.reset()
    }
    contactFields.forEach {
      it.reset()
    }
    if (!uebernehmeLieferadresse.value) {
      shippingFields.forEach {
        it.reset()
      }
    }
  }

  /**
   * Return an [Order] with all relevant values
   */
  fun toOrder(bundle: Bundle): Order {
    val billingAddress = OrderAddress(
      firstName = firstNameBilling.value!!,
      lastName = lastNameBilling.value!!,
      street = streetBilling.value!!,
      city = cityBilling.value!!,
      zip = zipBilling.value!!,
    )

    val shippingAddress = if (uebernehmeLieferadresse.value) {
      billingAddress
    } else {
      OrderAddress(
        firstName = firstNameShipping.value!!,
        lastName = lastNameShipping.value!!,
        street = streetShipping.value!!,
        city = cityShipping.value!!,
        zip = zipShipping.value!!,
      )
    }

    return Order(
      bundle = bundle,
      billingAddress = billingAddress,
      contactEmail = contactEmail.value!!,
      contactPhone = contactPhone.value!!,
      shippingAddress = shippingAddress,
      additionalNotes = additionalNotes.value,
      paymentMethod = paymentMethod.value,
      discountCode = (if (appState.appliedDiscount?.isValid == true) appState.appliedDiscount else null) as SuccessfullyValidatedDiscountCode?,
      seriousness = when (Server.instance) {
        Server.File -> Seriousness.JustTesting
        Server.Localhost -> Seriousness.JustTesting
        Server.Spielwiese -> Seriousness.JustTesting
        Server.Schaufenster -> Seriousness.JustTesting
        Server.Production -> Seriousness.Production
      },
    )
  }
}
