package de.wunschsolar.model.products

import it.neckar.open.unit.currency.EUR
import kotlinx.serialization.Serializable

/**
 * This class is a simple wrapper class used in [Bundle]
 * As [Bundle]s can contain multiple of the same [ProductComponent],
 *   [ProductComponentConfiguration] pairs a [ProductComponent] with an amount
 *   and adds a few utility functions
 */
@Serializable
data class ProductComponentConfiguration(
  val productComponent: ProductComponent,
  val amount: Int = 1,
  val imagePathOverride: String? = null,
) {
  val imagePath: String?
    get() = imagePathOverride ?: productComponent.imagePath

  val documentPath: String?
    get() = productComponent.documentPath

  private val price: @EUR Double = productComponent.price * amount

  val priceWithMarkup: @EUR Double = productComponent.priceWithMarkup * amount

  fun format(): String {
    return "${amount}x ${productComponent.description}"
  }
}
