
import components.modals.agbModal
import components.modals.datenschutzerklaerungModal
import components.modals.impressumModal
import components.modals.widerrufsbelehrungModal
import it.neckar.react.common.router.*
import kotlinx.css.*
import react.*
import remix.run.router.Router

val maxSiteWidth: LinearDimension = 1000.px

fun RBuilder.wunschsolarApp(): Unit = child(WunschsolarApp) {}

val WunschsolarApp: FC<Props> = fc("WunschsolarApp") {

  val (appState, setAppState) = useState(store.getState())

  useEffectOnce {
    store.subscribe {
      // Update the state whenever the store has been updated

      // This method triggers a rerender
      setAppState(store.getState())
    }
  }

  //Routing
  WunschsolarRoutes {}

  widerrufsbelehrungModal()

  agbModal()

  datenschutzerklaerungModal()

  impressumModal()
}

/**
 * Keep the router as field
 */
val appRouter: Router = Navigation.generateRouter()

val WunschsolarRoutes: FC<Props> = FC<Props> {
  react.router.dom.RouterProvider {
    router = appRouter
  }
}
