package services

import de.wunschsolar.model.DiscountCode
import de.wunschsolar.model.Feedback
import de.wunschsolar.model.Order
import it.neckar.react.common.*
import redux.FeedbackState
import redux.OrderState
import redux.actions.UpdateDiscountCodeAction
import redux.actions.UpdateFeedbackStateAction
import redux.actions.UpdateOrderStateAction
import services.http.MessageResult
import store

/**
 * Handles all UI actions
 */
object UiActions {

  /**
   * Sends the order to the server
   */
  fun order(newOrder: Order) {
    store.dispatch(UpdateOrderStateAction(OrderState.OrderSent))

    launchAndNotify("Bestellungsinformationen sind korrekt") {
      when (wunschsolarService.saveOrder(newOrder)) {
        MessageResult.Success -> store.dispatch(UpdateOrderStateAction(OrderState.OrderProcessed))
        MessageResult.Failure -> store.dispatch(UpdateOrderStateAction(OrderState.OrderFailed))
      }
    }
  }

  fun validateDiscountRequest(discountCode: DiscountCode) {
    launchAndNotify("Code abgeschickt") {
      store.dispatch(UpdateDiscountCodeAction(wunschsolarService.validateDiscountRequest(discountCode)))
    }
  }

  fun feedback(newFeedback: Feedback) {
    store.dispatch(UpdateFeedbackStateAction(FeedbackState.FeedbackSent))

    launchAndNotify("Feedback abgeschickt") {
      when (wunschsolarService.sendFeedback(newFeedback)) {
        MessageResult.Success -> store.dispatch(UpdateFeedbackStateAction(FeedbackState.FeedbackSuccess))
        MessageResult.Failure -> store.dispatch(UpdateFeedbackStateAction(FeedbackState.FeedbackFailed))
      }
    }
  }
}
