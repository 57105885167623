package components.schnellbestellung.forms.inputs

import it.neckar.react.common.*
import kotlinx.html.InputType
import react.*

/**
 * Holds a [StateInstance], its id, and an optional validation function for the required value of the [StateInstance]
 */
data class InputFieldData(
  /**
   * ID of the component
   */
  val id: String,

  /**
   * If the field is required for the form
   */
  val required: Boolean = true,

  /**
   * The input type for the input field
   * Text is the default
   */
  val inputType: InputType = InputType.text,

  /**
   * Optional function to check validity of input content
   */
  val validationFunction: (String?) -> ValidationState = {
    if (it?.isNotBlank() == true) {
      ValidationState.Valid
    } else if (it?.isBlank() == true && required) {
      ValidationState.Invalid
    } else {
      ValidationState.NotValidated
    }
  },
) {
  /**
   * StateInstance for this field
   */
  val textState: StateInstance<String?> = useState()

  val value: String?
    get() {
      return textState.value
    }


  fun calculateValidationState(): ValidationState {
    return validationFunction(textState.value)
  }

  fun isValid(): Boolean {
    return calculateValidationState() == ValidationState.Valid
  }

  fun reset() {
    if (textState.value == null) {
      textState.setter("")
    }
  }
}
