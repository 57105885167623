package de.wunschsolar.model

import de.wunschsolar.model.Message.MessageId
import de.wunschsolar.model.products.Bundle
import de.wunschsolar.model.products.PaymentMethod
import de.wunschsolar.model.products.ProductComponentConfiguration
import it.neckar.open.collections.fastForEach
import it.neckar.open.formatting.formatEuro
import it.neckar.open.i18n.I18nConfiguration
import kotlinx.serialization.Serializable

/**
 * Represents a wunschsolar.de order
 */
@Serializable
data class Order(
  override val id: MessageId = MessageId.random(),

  val bundle: Bundle,

  val billingAddress: OrderAddress,

  /**
   * The contact information for the customer
   */
  val contactEmail: String,
  val contactPhone: String,

  val shippingAddress: OrderAddress,

  val additionalNotes: String?,

  val paymentMethod: PaymentMethod,

  val discountCode: SuccessfullyValidatedDiscountCode?,

  override val seriousness: Seriousness,

  ) : Message {

  /**
   * Format this order into human-readable HTML to be put into an email
   */
  override fun html(): String {
    //language=HTML
    return """
    ${
      if (seriousness == Seriousness.JustTesting) {
        "<h1>TESTBESTELLUNG!</h1>"
      } else ""
    }

    <h3>Bestellung ${bundle.description}</h3>

    <div style='float:left; width:30%'>
      <p><b>Bestellungs-ID:</b></p>

      <p>
        <b>Rechnungsadresse</b><br>
        Vorname:<br>
        Nachname:<br>
        Straße:<br>
        Stadt:<br>
        Postleitzahl:
      </p>

      <p>
        <b>Kontaktinformationen</b><br>
        Email:<br>
        Telefon:
      </p>

      <p>
        <b>Versandadresse</b><br>
        Vorname:<br>
        Nachname:<br>
        Straße:<br>
        Stadt:<br>
        Postleitzahl:
      </p>

      <p>
        <b>Preis</b>
      </p>

      ${
      discountCode?.let {
        """
          <p>
            <b>Rabattcode</b>
          </p>
        """.trimIndent()
      }.orEmpty()
    }
    </div>

    <div style='float:left; width:60%; margin-left:10%'>
      <p>$id</p>

      <p>
        <br>
        ${billingAddress.firstName}<br>
        ${billingAddress.lastName}<br>
        ${billingAddress.street}<br>
        ${billingAddress.city}<br>
        ${billingAddress.zip}
      </p>

      <p>
        <br>
        <a href = mailto:$contactEmail>$contactEmail</a><br>
        <a href = tel:+$contactPhone>+$contactPhone</a>
      </p>

      <p>
        <br>
        ${shippingAddress.firstName}<br>
        ${shippingAddress.lastName}<br>
        ${shippingAddress.street}<br>
        ${shippingAddress.city}<br>
        ${shippingAddress.zip}
      </p>

      <p>
        ${
      buildString {
        val bundlePrice = bundle.bundlePrice - (bundle.paymentMethodDiscount(paymentMethod) ?: 0.0)
        append(bundlePrice.formatEuro(I18nConfiguration.Germany))
        if (discountCode != null) {
          append(" (${(bundlePrice * (1.0 - discountCode.discount)).formatEuro(I18nConfiguration.Germany)} mit Rabattcode)")
        }
      }
    }
      </p>

      ${
      discountCode?.let {
        """
            <p>
              ${it.format()}
            </p>
          """.trimIndent()
      }.orEmpty()
    }
    </div>

    <div style='float:left; width:100%;'>
    ${
      additionalNotes?.let {
        """
            <p>
              <b>Anmerkungen</b><br>
              $additionalNotes
            </p>
          """.trimIndent()
      }.orEmpty()
    }

      <b>Paketinhalt</b><br>
      <ul>
        ${
      buildString {
        bundle.content.fastForEach { it: ProductComponentConfiguration ->
          append("<li>${it.format()}</li>")
        }
      }
    }
      </ul>
    </div>
    """.trimIndent()
  }

  @Serializable
  data class OrderAddress(
    val firstName: String,
    val lastName: String,
    val street: String,
    val city: String,
    val zip: String,
  )
}
