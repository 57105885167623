package it.neckar.open.math

import kotlin.js.JsExport
import kotlin.math.exp

/**
 * Sigmoid function for curve fitting
 */
@JsExport
object Sigmoid {
  // Sigmoid function
  fun sigmoidFunction(x: Double, a: Double, b: Double, l: Double): Double {
    return l / (1 + exp(-a * (x - b)))
  }

  // Compute gradients for gradient descent
  fun computeGradients(parameters: DoubleArray, x: DoubleArray, y: DoubleArray, l: Double): DoubleArray {
    val a = parameters[0]
    val b = parameters[1]

    val predicted = x.map { sigmoidFunction(it, a, b, l) }
    val errors = predicted.toList().zip(y.toList()).map { (predicted, actual) -> predicted - actual }

    val gradientA = errors.toList().zip(x.toList()).map { (error, xValue) -> error * sigmoidFunction(xValue, a, b, l) * (1 - sigmoidFunction(xValue, a, b, l)) }
    val gradientB = errors.toList().zip(x.toList()).map { (error, xValue) -> error * a * sigmoidFunction(xValue, a, b, l) * (1 - sigmoidFunction(xValue, a, b, l)) }

    return doubleArrayOf(gradientA.sum(), gradientB.sum())
  }

  // Fit sigmoid curve using built-in functions
  fun fitSigmoid(x: DoubleArray, y: DoubleArray, learningRate: Double, maxIterations: Int, l: Double): DoubleArray {
    val initialGuess = doubleArrayOf(1.0, 1.0) // Initial guess for parameters (a, b)

    var parameters = initialGuess.copyOf()

    repeat(maxIterations) {
      val gradients = computeGradients(parameters, x, y, l)
      parameters = parameters.zip(gradients).map { (param, gradient) ->
        param - learningRate * gradient
      }.toDoubleArray()
    }

    return parameters
  }
}
