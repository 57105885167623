package components.schnellbestellung

import components.modals.compatabilityOverview
import de.wunschsolar.model.products.Bundle
import kotlinx.css.*
import kotlinx.html.classes
import maxSiteWidth
import react.*
import react.dom.*
import styled.*


/**
 * A summary of all the order information
 * and the button to confirm the order
 */
fun RBuilder.schnellbestellungKomplettpaketDetails(
  bundle: Bundle,
): Unit = child(schnellbestellungKomplettpaketDetails) {
  attrs {
    this.bundle = bundle
  }
}

val schnellbestellungKomplettpaketDetails: FC<SchnellbestellungKomplettpaketDetailsProps> = fc("schnellbestellungKomplettpaketDetails") { props ->

  val product = props.bundle


  styledDiv {
    attrs {
      classes = setOf("container", "mt-5", "px-4")
    }
    css {
      maxWidth = maxSiteWidth
    }

    div(classes = "row gy-3") {
      h3(classes = "col-12 mb-0") {
        +"Details"
      }

      komplettpaketContentOverview(product)

      div("my-2") {
        i(classes = "far fa-file-pdf pe-2") {}
        a(href = "montageanleitung-solar.pdf", classes = "link-dark", target = "_blank") {
          +"Montageanleitung"
        }
      }

      product.compatability?.let {
        compatabilityOverview(it)
      }

      //ablaufKomplettpaketOverview()
    }
  }
}


external interface SchnellbestellungKomplettpaketDetailsProps : Props {
  var bundle: Bundle
}
