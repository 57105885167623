package components.schnellbestellung.forms.inputs

import it.neckar.react.common.*
import kotlinx.html.CommonAttributeGroupFacade
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract


fun RBuilder.inputFieldDataAndLabel(
  /**
   * The value and setter for the value.
   * Must be created with a useState hook
   */
  inputFieldData: InputFieldData,

  /**
   * String to be displayed in a label above the input field
   */
  fieldName: String?,

  placeholder: String? = null,

  /**
   * The feedback given to the user of the input is valid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  validFeedback: String? = null,

  /**
   * The feedback given to the user of the input is invalid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  invalidFeedback: String? = null,

  ): Unit = child(inputFieldAndLabel) {
  attrs {
    this.inputFieldData = inputFieldData
    this.fieldName = fieldName
    this.placeholder = placeholder
    this.validFeedback = validFeedback
    this.invalidFeedback = invalidFeedback
  }
}

val inputFieldAndLabel: FC<InputFieldAndLabelProps> = fc("inputFieldAndLabel") { props ->
  val inputFieldData = props.inputFieldData
  val fieldValue = inputFieldData.textState.value
  val fieldSetter = inputFieldData.textState.setter

  val fieldRequired = inputFieldData.required


  props.fieldName?.let {
    label(classes = "form-label") {
      +"${it}${if (fieldRequired) "*" else ""}"
    }
  }

  input(classes = "form-control", type = inputFieldData.inputType) {
    attrs {
      id = inputFieldData.id
      value = fieldValue.orEmpty()
      placeholder = props.placeholder.orEmpty()

      onChangeFunction = useCallback(fieldSetter) { event ->
        val inputElement = event.target as HTMLInputElement
        fieldSetter(inputElement.value)
      }
      required = fieldRequired
    }

    validationStyle {
      inputFieldData.calculateValidationState()
    }
  }

  props.validFeedback?.let {
    div("valid-feedback") {
      +it
    }
  }

  props.invalidFeedback?.let {
    div("invalid-feedback") {
      +it
    }
  }
}

external interface InputFieldAndLabelProps : Props {
  /**
   * Is called on change
   */
  var inputFieldData: InputFieldData

  /**
   * String to be displayed in a label above the input field
   */
  var fieldName: String?

  var placeholder: String?

  /**
   * The feedback given to the user of the input is valid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  var validFeedback: String?

  /**
   * The feedback given to the user of the input is invalid
   * A value of null means no feedback is given
   * An empty string means only a highlight without text is shown
   */
  var invalidFeedback: String?
}


/**
 * Adds the "invalid" class if the provided invalid check fails
 */
inline fun <T : CommonAttributeGroupFacade> RDOMBuilder<T>.invalidIf(invalidCheck: () -> Boolean) {
  contract {
    callsInPlace(invalidCheck, InvocationKind.EXACTLY_ONCE)
  }

  addClassIf("is-invalid", invalidCheck)
}
