package de.wunschsolar.model

import it.neckar.open.formatting.formatAsPercentage
import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable

/**
 * Represents a wunschsolar.de discount request
 */
@Serializable
data class DiscountCode(val code: String)


sealed interface ValidatedDiscountCode {
  val isValid: Boolean
    get() {
      return this is SuccessfullyValidatedDiscountCode
    }
}

@Serializable
data class SuccessfullyValidatedDiscountCode(
  val code: DiscountCode,
  val discount: @pct Double,
) : ValidatedDiscountCode {
  fun format(): String {
    return "${code.code}: Rabattcode ${discount.formatAsPercentage(0)}"
  }
}

@Serializable
data class UnsuccessfullyValidatedDiscountCode(
  val code: DiscountCode,
  val message: String,
) : ValidatedDiscountCode

@Serializable
data class AlreadyValidatedDiscountCode(
  val code: DiscountCode,
  val message: String,
) : ValidatedDiscountCode
