package services.http

import it.neckar.ktor.client.postJson
import de.wunschsolar.model.DiscountCode
import de.wunschsolar.model.Feedback
import de.wunschsolar.model.Order
import de.wunschsolar.model.ValidatedDiscountCode
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.util.*
import io.ktor.util.date.*
import io.ktor.utils.io.*
import services.http.WunschsolarUrlSupport.StorageServiceUrls.OrderUrls

/**
 * Stores / retrieves information using REST
 */
class WunschsolarClientService(val httpClient: HttpClient) {

  /**
   * Called by the [UiActions] to save an order
   */
  suspend fun saveOrder(order: Order): MessageResult {
    try {
      val response = httpClient.postJson(OrderUrls.order, order) {
        featureFlagsHeader()
      }

      if (response.status.isSuccess()) {
        return MessageResult.Success
      }
    } catch (e: Throwable) {
      return when (e) {
        is ClientRequestException, is Error -> MessageResult.Failure
        else -> throw e
      }
    }

    return MessageResult.Failure
  }

  suspend fun validateDiscountRequest(discountCode: DiscountCode): ValidatedDiscountCode {
    return httpClient.postJson(OrderUrls.discount, discountCode) {
      featureFlagsHeader()
    }.body()
  }

  /**
   * Called by the [UiActions] to save an order
   */
  suspend fun sendFeedback(feedback: Feedback): MessageResult {
    try {
      val response = httpClient.postJson(OrderUrls.feedback, feedback) {
        featureFlagsHeader()
      }

      if (response.status.isSuccess()) {
        return MessageResult.Success
      }
    } catch (e: Throwable) {
      return when (e) {
        is ClientRequestException, is Error -> MessageResult.Failure
        else -> throw e
      }
    }

    return MessageResult.Failure
  }
}

//TODO sealed class? With error message?
enum class MessageResult {
  Success,
  Failure,
}
