package components.modals

import it.neckar.react.common.*
import react.*
import react.dom.*


/**
 * The confirmation modal to be displayed when the submit button is pressed and all field inputs are valid
 */
fun RBuilder.datenschutzerklaerungModal(): Unit = child(datenschutzerklaerungModal) {}

val datenschutzerklaerungModal: FC<DatenschutzerklaerungModalProps> = fc("datenschutzerklaerungModal") {

  modal(
    id = "datenschutzerklaerung",
    title = "Datenschutzerklärung",
    modalCentered = true,
  ) {

    h5 {
      +"Wer bei uns für die Datenverarbeitung verantwortlich ist"
    }
    p {
      +"Neckar IT GmbH"
      br {}
      +"Ulrichstraße 25"
      br {}
      +"72116 Mössingen"
      br {}
      +"Deutschland"
      br {}

      +"E-Mail: "
      a(href = "mailto:info@neckar.it") {
        +"info@neckar.it"
      }
    }
    //p {
    //  +"Lizergy GmbH"
    //  br {}
    //  +"Simon Hänel"
    //  br {}
    //  +"Jaspisstrasse 13"
    //  br {}
    //  +"72250 Freudenstadt"
    //  br {}
    //  +"Deutschland"
    //  br {}
    //
    //  +"E-Mail: "
    //  a(href = "mailto:info@lizergy.com") {
    //    +"info@lizergy.com"
    //  }
    //}

    h5("mt-4") {
      +"Verarbeitung Ihrer Daten im Rahmen der von uns erbrachten handwerklichen Leistungen"
    }
    p {
      +"Sofern Sie unser Kunde oder Geschäftspartner sind oder sich für die Leistungen unseres Handwerksbetriebes interessieren, richtet sich Art, Umfang und Zweck der Verarbeitung Ihrer Daten nach dem zwischen uns bestehenden vertraglichen bzw. vorvertraglichen Beziehungen. In diesem Sinne gehören zu den von uns verarbeiteten Daten all diejenigen Daten, die wir von Ihnen abfragen oder die Sie uns bereitstellen, um Ihre Anfrage zu beantworten, Ihnen ein Angebot zu erstellen oder Ihre Bestellung zu bearbeiten. Sofern sich aus den weiteren Hinweisen dieser Datenschutzerklärung nichts anderes ergibt, beschränkt sich die Verarbeitung Ihrer Daten sowie deren Weitergabe an Dritte auf diejenigen Daten, die zur Beantwortung Ihrer Anfragen und/oder zur Erfüllung des zwischen Ihnen und uns geschlossenen Vertrages, zur Wahrung unserer Rechte sowie zur Erfüllung gesetzlicher Pflichten erforderlich und zweckmäßig sind. Welche Daten hierfür erforderlich sind, teilen wir Ihnen vor oder im Rahmen der Datenerhebung mit. Soweit wir zur Erbringung unserer Leistungen Drittanbieter einsetzen, gelten die Datenschutzhinweise der jeweiligen Drittanbieter."
    }
    p {
      b { +"Betroffene Daten:" }
    }
    div {
      ul {
        li { +"Bestandsdaten (bspw. Namen, Adressen)" }
        li { +"Zahlungsdaten (bspw. Bankverbindungsdaten, Rechnungen)" }
        li { +"Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)" }
        li { +"Vertragsdaten (bspw. Vertragsgegenstand, Vertragsdauer)" }
      }
    }
    p {
      b { +"Betroffene Personen: " }
      +"Interessenten, Geschäfts- und Vertragspartner"
    }
    p {
      b { +"Verarbeitungszweck: " }
      +"Abwicklung vertraglicher Leistungen, Kommunikation sowie Beantwortung von Kontaktanfragen, Büro und Organisationsverfahren"
    }
    p {
      b { +"Rechtsgrundlage: " }
      +"Vertragserfüllung und vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, rechtliche Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO, berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO"
    }

    h5("mt-4") {
      +"Ihre Rechte nach der DSGVO"
    }
    p {
      +"Nach der DSGVO stehen Ihnen die nachfolgend aufgeführten Rechte zu, die Sie jederzeit bei dem in Ziffer 1. dieser Datenschutzerklärung genannten Verantwortlichen geltend machen können:"
    }
    div {
      ul {
        li {
          b { +"Recht auf Auskunft: " }
          +"Sie haben das Recht, von uns Auskunft darüber zu verlangen, ob und welche Daten wir von Ihnen verarbeiten."
        }
        li {
          b { +"Recht auf Berichtigung " }
          +"Sie haben das Recht, die Berichtigung unrichtiger oder Vervollständigung unvollständiger Daten zu verlangen."
        }
        li {
          b { +"Recht auf Löschung: " }
          +"Sie haben das Recht, die Löschung Ihrer Daten zu verlangen."
        }
        li {
          b { +"Recht auf Einschränkung: " }
          +"Sie haben in bestimmten Fällen das Recht zu verlangen, dass wir Ihre Daten nur noch eingeschränkt bearbeiten."
        }
        li {
          b { +"Recht auf Datenübertragbarkeit: " }
          +"Sie haben das Recht zu verlangen, dass wir Ihnen oder einem anderen Verantwortlichen Ihre Daten in einem strukturierten, gängigen und maschinenlesebaren Format übermitteln."
        }
        li {
          b { +"Beschwerderecht: " }
          +"Sie haben das Recht, sich bei einer Aufsichtsbehörde zu beschweren. Zuständig ist die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres Arbeitsplatzes oder unseres Firmensitzes."
        }
      }
    }

    h6 {
      +"Widerrufsrecht"
    }
    p {
      +"Sie haben das Recht, die von Ihnen erteilte Einwilligung zur Datenverarbeitung jederzeit zu widerrufen."
    }

    h6 {
      +"Widerspruchsrecht"
    }
    p {
      +"Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer Daten, die wir auf unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO stützen, Widerspruch einzulegen. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen, bitten wir Sie um die Darlegung der Gründe. Wir werden Ihre personenbezogenen Daten dann nicht mehr verarbeiten, es sei denn, wir können Ihnen gegenüber nachweisen, dass zwingende schutzwürdige Gründe an der Datenverarbeitung Ihre Interessen und Rechte überwiegen."
    }

    p {
      span("text-decoration-underline") {
        b { +"Unabhängig vom vorstehend Gesagten, haben Sie das jederzeitige Recht, der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse zu widersprechen." }
      }
    }

    p {
      +"Ihren Widerspruch richten Sie bitte an die oben angegebene Kontaktadresse des Verantwortlichen."
    }

    h5("mt-4") {
      +"Wann löschen wir Ihre Daten?"
    }
    p {
      +"Wir löschen Ihre Daten dann, wenn wir diese nicht mehr brauchen oder Sie uns dies vorgeben. Dass bedeutet, dass - sofern sich aus den einzelnen Datenschutzhinweisen dieser Datenschutzerklärung nichts anderes ergibt - wir Ihre Daten löschen,"
    }
    div {
      ul {
        li {
          +"wenn der Zweck der Datenverarbeitung weggefallen ist und damit die jeweilige in den einzelnen Datenschutzhinweisen genannte Rechtsgrundlage nicht mehr besteht, also bspw."
          ul {
            li { +"nach Beendigung der zwischen uns bestehenden vertraglichen oder mitgliedschaftlichen Beziehungen (Art.6 Abs . 1 lit. a DSGVO) oder" }
            li { +"nach Wegfall unseres berechtigten Interesses an der weiteren Verarbeitung oder Speicherung Ihrer Daten(Art.6 Abs . 1 lit . f DSGVO)," }
          }
        }
        li { +"wenn Sie von Ihrem Widerrufsrecht Gebrauch machen und keine anderweitige gesetzliche Rechtsgrundlage für die Verarbeitung im Sinne von Art . 6 Abs. 1 lit. b-f DSGVO eingreift," }
        li { +"wenn Sie vom Ihrem Widerspruchsrecht Gebrauch machen und der Löschung keine zwingenden schutzwürdigen Gründe entgegenstehen." }
      }
      p {
        +"Sofern wir (bestimmte Teile) Ihre(r) Daten jedoch noch für andere Zwecke vorhalten müssen, weil dies etwa steuerliche Aufbewahrungsfristen (in der Regel 6 Jahre für Geschäftskorrespondenz bzw. 10 Jahre für Buchungsbelege) oder die Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen aus vertraglichen Beziehungen (bis zu vier Jahren) erforderlich machen oder die Daten zum Schutz der Rechte einer anderen natürlichen oder juristischen Person gebraucht werden, löschen wir (den Teil) Ihre(r) Daten erst nach Ablauf dieser Fristen. Bis zum Ablauf dieser Fristen beschränken wir die Verarbeitung dieser Daten jedoch auf diese Zwecke (Erfüllung der Aufbewahrungspflichten)."
      }
    }

    h5("mt-4") {
      +"Cookies"
    }
    p {
      +"Unsere Internetseite nutzt Cookies. Bei Cookies handelt es sich um kleine Textdateien, bestehend aus einer Reihe von Zahlen und Buchstaben, die auf dem von Ihnen genutzten Endgerät abgelegt und gespeichert werden. Cookies dienen vorrangig dazu, Informationen zwischen dem von Ihnen genutzten Endgerät und unserer Webseite auszutauschen. Hierzu gehören u. a. die Spracheinstellungen auf einer Webseite, der Login-Status oder die Stelle, an der ein Video geschaut wurde."
    }
    p {
      +"Beim Besuch unserer Webseiten werden zwei Typen von Cookies eingesetzt:"
    }
    div {
      ul {
        li {
          b { +"Temporäre Cookies(Session Cookies):" }
          +"Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder Ihren Browser schließen."
        }
        li {
          b { +"Permanente Cookies: " }
          +"Permanente Cookies bleiben auch nach dem Schließen des Browsers gespeichert. Dadurch erkennt unsere Webseite Ihren Rechner wieder, wenn Sie auf unsere Webseite zurückkehren. In diesen Cookies werden beispielsweise Informationen zu Spracheinstellungen oder Log-In-Informationen gespeichert. Außerdem kann mit diesen Cookies Ihr Surfverhalten dokumentiert und gespeichert werden. Diese Daten können zu Statistik -, Marketing-und Personalisierungs-Zwecken verwendet werden."
        }
      }
    }
    p {
      +"Neben der vorstehenden Einteilung können Cookies auch im Hinblick auf ihren Einsatzzweck unterschieden werden:"
    }
    div {
      ul {
        li {
          b { +"Notwendige Cookies: " }
          +"Dies sind Cookies, die für den Betrieb unserer Webseite unbedingt erforderlich sind, um Logins oder Warenkörbe für die Dauer Ihrer Sitzung zu speichern oder Cookies, die aus Sicherheitsgründen gesetzt werden."
        }
        li {
          b { +"Statistik -, Marketing-und Personalisierungs-Cookies: " }
          +"Dies sind Cookies, die für Analysezwecke oder die Reichweitenmessung eingesetzt werden. Über solche \"Tracking\"-Cookies können insbesondere Informationen zu eingegebenen Suchbegriffen oder die Häufigkeit von Seitenaufrufen gespeichert sein. Daneben kann aber auch das Surfverhalten eines einzelnen Nutzers (z.B.Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) in einem Nutzerprofil gespeichert werden. Solche Profile werden genutzt, um Nutzern Inhalte anzuzeigen, die deren potentiellen Interessen entsprechen. Soweit wir Dienste nutzen, über die Cookies zu Statistik-, Marketing-und Personalisierungs-Zwecken auf Ihrem Endgerät gespeichert werden, informieren wir Sie hierzu gesondert in den nachfolgenden Abschnitten unserer Datenschutzerklärung oder im Rahmen der Einholung Ihrer Einwilligung."
        }
      }
    }
    p {
      b { +"Betroffene Daten:" }
    }
    ul {
      li { +"Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)" }
      li { +"Kommunikationsdaten (bspw. Informationen über das genutzte Gerät, IP-Adresse)." }
    }
    p {
      b { +"Betroffene Personen: " }
      +"Nutzer unserer Onlineangebote"
    }
    p {
      b { +"Verarbeitungszweck: " }
      +"Ausspielen unserer Internetseiten, Gewährleistung des Betriebs unserer Internetseiten, Verbesserung unseres Internetangebotes, Kommunikation und Marketing."
    }
    p {
      b {
        +"Rechtsgrundlage:"
        br {}
        +"Berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO"
      }
      br {}
      +"Sofern wir von Ihnen keine Einwilligung in das Setzen der Cookies einholen, stützen wir die Verarbeitung Ihrer Daten auf unser berechtigtes Interesse, die Qualität und Benutzerfreundlichkeit unseres Internetauftritts, insbesondere der Inhalte und Funktionen zu verbessern. Sie haben über die Sicherheitseinstellungen Ihres Browsers, dem Einsatz der von uns im Rahmen unseres berechtigten Interesses gesetzten Cookies zu widersprechen. Dort haben Sie die Möglichkeit festzulegen, ob Sie etwa von vornherein keine oder nur auf Nachfrage Cookies akzeptieren oder aber festlegen, dass Cookies nach jedem Schließen Ihres Browsers gelöscht werden. Werden Cookies für unsere Webseite deaktiviert, können möglicherweise nicht mehr alle Funktionen der Webseite vollumfänglich genutzt werden."
    }

    h5("mt-4") {
      +"Webhosting"
    }
    p { +"Wir bedienen uns zum Vorhalten unserer Internetseiten eines Anbieters, auf dessen Server unsere Internetseiten gespeichert und für den Abruf im Internet verfügbar gemacht werden(Hosting). Hierbei können von dem Anbieter all diejenigen über den von Ihnen genutzten Browser übertragenen Daten verarbeitet werden, die bei der Nutzung unserer Internetseiten anfallen. Hierzu gehören insbesondere Ihre IP-Adresse, die der Anbieter benötigt, um unser Online-Angebot an den von Ihnen genutzten Browser ausliefern zu können sowie sämtliche von Ihnen über unsere Internetseite getätigten Eingaben. Daneben kann der von uns genutzte Anbieter" }
    ul {
      li { +"das Datum und die Uhrzeit des Zugriffs auf unsere Internetseite" }
      li { +"Zeitzonendifferenz zur Greenwich Mean Time(GMT)" }
      li { +"Zugriffsstatus(HTTP-Status)" }
      li { +"die übertragene Datenmenge" }
      li { +"der Internet-Service-Provider des zugreifenden Systems" }
      li { +"der von Ihnen verwendete Browsertyp und dessen Version" }
      li { +"das von Ihnen verwendete Betriebssystem" }
      li { +"die Internetseite, von welcher Sie gegebenenfalls auf unsere Internetseite gelangt sind" }
      li { +"die Seiten bzw. Unterseiten, welche Sie auf unserer Internetseite besuchen." }
    }
    p { +"erheben. Die vorgenannten Daten werden als Logfiles auf den Servern unseres Anbieters gespeichert. Dies ist erforderlich, um die Stabilität und Sicherheit des Betriebs unserer Internetseite zu gewährleisten." }

    p {}

    p {
      b { +"Betroffene Daten:" }
    }
    ul {
      li { +"Inhaltsdaten(bspw. Posts, Fotos, Videos)" }
      li { +"Nutzungsdaten(bspw. Zugriffszeiten, angeklickte Webseiten)" }
      li { +"Kommunikationsdaten(bspw. Informationen über das genutzte Gerät, IP-Adresse)" }
    }
    p {
      b { +"Betroffene Personen: " }
      +"Nutzer unserer Internetpräsenz"
    }
    p {
      b { +"Verarbeitungszweck: " }
      +"Ausspielen unserer Internetseiten, Gewährleistung des Betriebs unserer Internetseiten"
    }
    p {
      b { +"Rechtsgrundlage: " }
      +"Berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO"
    }
    p {
      b { +"Von uns beauftragte(r) Webhoster:" }
    }
    p {
      b { +"Hetzner Online" }
      br {}
      +"Dienstanbieter: Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen"
      br { }

      +"Internetseite: "
      a(href = "https://www.hetzner.de/") {
        +"https://www.hetzner.de/"
      }

      br {}

      +"Datenschutzerklärung: "
      a(href = "https://www.hetzner.de/rechtliches/datenschutz") {
        +"https://www.hetzner.de/rechtliches/datenschutz"
      }
    }

    h5("mt-4") {
      +"Kontaktaufnahme"
    }
    p { +"Soweit Sie uns über E-Mail, Soziale Medien, Telefon, Fax, Post, unser Kontaktformular oder sonstwie ansprechen und uns hierbei personenbezogene Daten wie Ihren Namen, Ihre Telefonnummer oder Ihre E-Mail-Adresse zur Verfügung stellen oder weitere Angaben zur Ihrer Person oder Ihrem Anliegen machen, verarbeiten wir diese Daten zur Beantwortung Ihrer Anfrage im Rahmen des zwischen uns bestehenden vorvertraglichen oder vertraglichen Beziehungen." }
    p {
      b { +"Betroffene Daten:" }
    }
    ul {
      li { +"Bestandsdaten(bspw. Namen, Adressen)" }
      li { +"Kontakdaten(bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)" }
      li { +"Inhaltsdaten(Texte, Fotos, Videos)" }
      li { +"Vertragsdaten(bspw. Vertragsgegenstand, Vertragsdauer)" }
    }
    p {
      b { +"Betroffene Personen: " }
      +"Interessenten, Kunden, Geschäfts- und Vertragspartner"
    }
    p {
      b { +"Verarbeitungszweck: " }
      +"Kommunikation sowie Beantwortung von Kontaktanfragen, Büro und Organisationsverfahren"
    }
    p {
      b { +"Rechtsgrundlage: " }
      +"Vertragserfüllung und vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO"
    }

    h5("mt-4") {
      +"Registrierung, Anmeldung und Nutzerkonto"
    }
    p {
      +"Sie haben die Möglichkeit sich auf unserem Online-Medium zu registrieren, um dort ein Nutzerkonto anzulegen. Hierzu ist die Angabe personenbezogener Daten notwendig, die sich aus der Eingabemaske ergeben. Zu den dort abgefragten Daten gehören insbesondere Ihr Name, Ihre E-Mail-Adresse, ggfls. ein Nutzername sowie das Passwort. Diese Daten werden von uns gespeichert und verarbeitet, um für Sie ein Nutzerkonto einzurichten und die (wiederholte) Anmeldung zu ermöglichen. Die Daten können jederzeit durch Sie geändert oder gelöscht werden. Die Daten werden nicht an Dritte weitergegeben außer dies dient der technischen und organisatorischen Abwicklung des zwischen uns bestehenden Nutzungsvertrages. Um Sie und uns vor missbräuchlichen Registrierungen zu schützen, speichern wir die Ihnen im Zeitpunkt der Registrierung zugeordnete IP-Adresse, sowie das Datum und die Uhrzeit der Registrierung."
    }
    p {
      b { +"Betroffene Daten:" }
    }
    ul {
      li { +"Bestandsdaten(bspw. Namen, Adressen)" }
      li { +"Kontakdaten(bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)" }
      li { +"Vertragsdaten(bspw. Vertragsgegenstand, Vertragsdauer)" }
      li { +"Zahlungsdaten(bspw. Bankverbindungsdaten, Rechnungen)" }
      li { +"Inhaltsdaten(bspw. Posts, Fotos, Videos)" }
      li { +"Nutzungsdaten(bspw. Zugriffszeiten, angeklickte Webseiten)" }
      li { +"Kommunikationsdaten(bspw. Informationen über das genutzte Gerät, IP-Adresse)" }
    }
    p {
      b { +"Verarbeitungszweck: " }
      +"Abwicklung vertraglicher Leistungen, Kommunikation sowie Beantwortung von Kontaktanfragen, Sicherheitsmaßnahmen."
    }
    p {
      b { +"Rechtsgrundlage:" }
      +"Vertragserfüllung und vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, rechtliche Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO, berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO"
    }
    p {
      b { +"Löschung:" }
      +"Siehe hierzu den Punkt: "
      b { +"\"Wann löschen wir Ihre Daten?\"." }
      +"Daneben möchten wir Sie darauf aufmerksam machen, dass wir die im Rahmen der Registrierung erhobenen Daten sowie die in dem Account hinterlegten Inhaltsdaten vorbehaltlich entgegenstehender gesetzlicher Aufbewahrungspflichten löschen, sobald Sie Ihren Account löschen. Wir bitten Sie daher, sofern Sie auf die in Ihrem Account hinterlegten Inhaltsdaten auch nach der Löschung Ihres Account zugreifen wollen oder müssen, diese vor der Löschung des Accounts anderweitig zu sichern."
    }

    h5("mt-4") {
      +"Verarbeitung Ihrer Daten bei einer Bestellung in unserem Online-Shop"
    }
    p {
      +"Sofern Sie über unseren Online-Shop Bestellungen aufgeben, verarbeiten wir die von Ihnen im Bestellprozess gemachten Angaben, um Ihre Bestellung abwickeln zu können. Hierzu gehören insbesondere Ihr Name sowie Ihre Adress- sowie elektronischen Kontaktdaten, Informationen zur Abwicklung des Bezahlvorgangs sowie die Angaben zu Ihrer konkreten Bestellung. Welche Daten wir im Einzelnen benötigen, teilen wir Ihnen im Rahmen des Bestellvorgangs mit."
    }
    p {
      +"Sofern es zur Erfüllung des zwischen uns geschlossenen Vertrages, zum Schutz Ihrer lebensnotwendiger Interessen oder wegen gesetzlicher Bestimmungen erforderlich ist, übermitteln wir Ihre Daten unter Beachtung Ihrer Rechte an Dritte, wie bspw. an das mit der Lieferung beauftragte Logistik-Unternehmen, an Zahlungsdienstleister zum Zwecke der Zahlungsabwicklung sowie unseren Steuerberater. Soweit wir zur Erbringung unserer Leistungen Drittanbieter einsetzen, gelten die Datenschutzhinweise der jeweiligen Drittanbieter."
    }
    p {
      b { +"Nutzerkonten:" }
      +"Sie können freiwillig ein Nutzerkonto anlegen, in dem Sie Ihre Bestellungen einsehen und verwalten können. Wenn Sie Ihr Konto kündigen, werden Ihre Daten gelöscht. Es obliegt Ihnen, Ihre Daten vor der Kündigung zu sichern. Wir verwenden transiente Cookies (zum Begriff s. o. unter Cookies) für die Speicherung des Warenkorb-Inhalts sowie persistente Cookies (zum Begriff s. o. unter Cookies) für die Speicherung des Login-Status. Wenn Sie eine Bestellung vornehmen, sich für ein Nutzerkonto registrieren oder dort erneut anmelden, speichern wir Ihre IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Zweck der Speicherung sind unsere aber ebenso auch Ihre berechtigten Interessen am Schutz vor dem Missbrauch und sonstiger unbefugter Nutzung."
    }
    p {
      b { +"Betroffene Daten:" }
    }
    ul {
      li { +"Bestandsdaten(bspw. Namen, Adressen)" }
      li { +"Zahlungsdaten(bspw. Bankverbindungsdaten, Rechnungen)" }
      li { +"Kontakdaten(bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)" }
      li { +"Vertragsdaten(bspw. Vertragsgegenstand, Vertragsdauer)" }
      li { +"Nutzungsdaten(bspw. Zugriffszeiten, angeklickte Webseiten)" }
      li { +"Kommunikationsdaten(bspw. Informationen über das genutzte Gerät, IP-Adresse)" }
    }
    p {
      b { +"Betroffene Personen: " }
      +"Kunden, Interessenten, Geschäfts- und Vertragspartner"
    }
    p {
      b { +"Verarbeitungszweck: " }
      +"Abwicklung von Bestellungen, Kommunikation und ggfs. Marketing sowie Beantwortung von Anfragen, Datensicherheit, Büro und Organisationsverfahren"
    }
    p {
      b { +"Rechtsgrundlage:" }
      +"Vertragserfüllung und vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, rechtliche Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO, berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO"
    }

    /* Trackboxx Datenschutzerklärung */
    h5("mt-4") {
      +"Webanalysedienst Trackboxx"
    }

    p {
      +"Wir nutzen auf unserer Seite den Webanalysedienst "
      a(href = "https://trackboxx.com/") { +"Trackboxx." }
    }

    p {
      +"Wir werten damit Besucherzugriffe statistisch aus und analysieren die Nutzung unseres Internetauftritts. Die Daten werden zum genannten Zweck anonymisiert auf einem Server in Deutschland gespeichert und unterliegen den Vorgaben der DSGVO."
    }

    p {
      +"Wir setzen dabei keinerlei Cookies ein oder speichern personenbezogene Daten. Vielmehr wird Ihre IP-Adresse dazu verwendet einen Code zu generieren, der dann einer anonymen User-ID zugewiesen wird, solange Sie auf unserer Webseite unterwegs sind."
    }

    p {
      +"Diese Daten können keiner bestimmten Person zugeordnet werden und sind mit einem täglich wechselnden Code verschlüsselt. Es ist somit auch nicht möglich, Sie bei einem erneuten Besuch auf unserer Webseite „wiederzuerkennen“."
    }

    p {
      +"Ein seitenübergreifendes Tracking, eine Verknüpfung der Daten mit anderen Quellen oder eine Weitergabe der Informationen an Dritte findet nicht statt."
    }

    p {
      +"Rechtsgrundlage für die Verarbeitung ist Art. 6, Abs.1, lit. f, DSGVO. Unser berechtigtes Interesse liegt in der bedarfsgerechten Gestaltung und Optimierung unseres Internetauftrittes."
    }

    p {
      +"Wenn Sie dennoch nicht mithelfen möchten, unsere Seite zu verbessern, können Sie das jederzeit über die „Do-Not-Track-Funktion“ in Ihrem Browser steuern. Wir haben hier Links zusammengefasst, wie das bei den gängigsten Browsertypen funktioniert:"
    }

    div {
      a(href = "https://support.microsoft.com/de-de/help/17288/windows-internet-explorer-11-use-do-not-track") {
        +"Internet Explorer 11"
      }
      br {}
      a(href = "https://support.mozilla.org/de/kb/wie-verhindere-ich-dass-websites-mich-verfolgen") {
        +"Mozilla Firefox"
      }
      br {}
      a(href = "https://support.google.com/chrome/answer/2790761?co=GENIE.Platform%3DDesktop&amp;hl=de") {
        +"Google Chrome"
      }
    }

    /*  Ende Trackboxx Datenschutzerklärung */

    h5("mt-4") {
      +"Sicherheitsmaßnahmen"
    }
    p {
      +"Wir treffen im Übrigen technische und organisatorische Sicherheitsmaßnahmen nach dem Stand der Technik, um die Vorschriften der Datenschutzgesetze einzuhalten und Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen."
    }

    h5("mt-4") {
      +"Aktualität und Änderung dieser Datenschutzerklärung"
    }
    p {
      +"Diese Datenschutzerklärung ist aktuell gültig und hat den Stand November 2021. Aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung anzupassen."
    }
    p {
      b { +"Diese Datenschutzerklärung wurde mit Hilfe des Datenschutz-Generators von SOS Recht erstellt. SOS Recht ist ein Angebot der Mueller.legal Rechtsanwälte Partnerschaft mit Sitz in Berlin." }
    }
  }
}


external interface DatenschutzerklaerungModalProps : Props
