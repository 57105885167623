package services

import kotlinx.browser.window

/**
 * Describes the environment this application runs on
 */
enum class Server {
  /**
   * File (local deployment)
   */
  File {
    override fun matchesServerUrl(serverUrl: String): Boolean {
      return serverUrl.startsWith("file://")
    }
  },

  /**
   * Localhost / development
   */
  Localhost {
    override fun matchesServerUrl(serverUrl: String): Boolean {
      return serverUrl.contains("://localhost")
    }
  },

  /**
   * Spielwiese server
   */
  Spielwiese {
    override fun matchesServerUrl(serverUrl: String): Boolean {
      return serverUrl.contains("https://spielwiese.wunschsolar.de")
    }
  },

  Schaufenster {
    override fun matchesServerUrl(serverUrl: String): Boolean {
      return serverUrl.contains("https://schaufenster.wunschsolar.de")
    }
  },

  /**
   * Production.
   * Attention: Must be checked for last!
   */
  Production {
    override fun matchesServerUrl(serverUrl: String): Boolean {
      return serverUrl.contains("https://wunschsolar.de")
    }
  },
  ;

  /**
   * Returns true if the given server url matches the sever enum
   */
  abstract fun matchesServerUrl(serverUrl: String): Boolean

  companion object {
    /**
     * The current server this application is hosted on
     */
    val instance: Server = get()

    /**
     * Returns the current server instance
     */
    fun get(): Server {
      val serverUrl = window.location.href

      return entries.firstOrNull {
        it.matchesServerUrl(serverUrl)
      } ?: throw IllegalStateException("Could not identify server for url $serverUrl")
    }
  }
}
