package components

import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.id
import kotlinx.html.tabIndex
import maxSiteWidth
import react.*
import react.dom.*
import react.router.dom.*
import styled.*
import web.cssom.ClassName


fun RBuilder.navbar(): Unit = child(navbar) {}


val navbar: FC<NavbarProps> = fc("navbar") {

  nav(classes = "navbar navbar-light bg-light sticky-top") {
    styledDiv {
      attrs {
        classes = setOf("container-fluid")
      }
      css {
        maxWidth = maxSiteWidth
      }

      button(classes = "navbar-toggler me-1", type = ButtonType.button) {
        attrs["data-bs-toggle"] = "offcanvas"
        attrs["data-bs-target"] = "#offcanvasNavbar"
        attrs["aria-controls"] = "offcanvasNavbar"

        span("navbar-toggler-icon") {}
      }

      Link {
        div(classes = "navbar-brand") {
          img(src = "./img/wunschsolar-wortmarke.png", alt = "") {
            attrs {
              height = "35"
            }
          }
        }

        attrs {
          to = "/"
        }
      }

      a(classes = "navbar-brand ms-auto", href = "https://www.lizergy.com/", target = "_blank") {
        attrs {
          rel = "noopener noreferrer"
        }

        if (false) {
          img(src = "./img/lizergy-powered-logo.png", alt = "") {
            attrs {
              height = "35"
            }
          }
        }
      }

      div(classes = "offcanvas offcanvas-start") {
        attrs {
          id = "offcanvasNavbar"
          tabIndex = "-1"
        }
        attrs["aria-labelledby"] = "offcanvasNavbarLabel"
        attrs["data-bs-scroll"] = "true"
        attrs["data-bs-backdrop"] = "false"

        div(classes = "offcanvas-header bg-dark text-white") {
          h5(classes = "offcanvas-title") {
            attrs {
              id = "offcanvasNavbarLabel"
            }

            +"Menü"
          }
          button(type = ButtonType.button, classes = "btn-close btn-close-white") {
            attrs["data-bs-dismiss"] = "offcanvas"
            attrs["aria-label"] = "Close"
          }
        }
        div(classes = "offcanvas-header bg-secondary") {
          h6(classes = "offcanvas-title") {
            +"SHOP"
          }
        }
        div(classes = "offcanvas-body") {
          ul(classes = "navbar-nav justify-content-end flex-grow-1 pe-3") {
            li(classes = "nav-item") {
              Link {
                attrs {
                  className = ClassName("nav-link active")
                  to = "/"
                }

                +"Komplettpakete"
              }
            }
          }
        }

        div(classes = "offcanvas-header bg-secondary") {
          h6(classes = "offcanvas-title") {
            +"INFOS"
          }
        }
        div(classes = "offcanvas-body") {
          ul(classes = "navbar-nav justify-content-end flex-grow-1 pe-3") {
            li(classes = "nav-item") {
              Link {
                attrs {
                  className = ClassName("nav-link active")
                  to = "/Ueber-uns"
                }

                +"Über uns"
              }
            }
            li(classes = "nav-item") {
              a(classes = "nav-link active", href = "./") {
                attrs["data-bs-toggle"] = "modal"
                attrs["data-bs-target"] = "#widerrufsbelehrung"

                +"Widerrufsbelehrung"
              }
            }
            li(classes = "nav-item") {
              a(classes = "nav-link active", href = "./") {
                attrs["data-bs-toggle"] = "modal"
                attrs["data-bs-target"] = "#agbs"

                +"AGB"
              }
            }
            li(classes = "nav-item") {
              a(classes = "nav-link active", href = "./") {
                attrs["data-bs-toggle"] = "modal"
                attrs["data-bs-target"] = "#datenschutzerklaerung"

                +"Datenschutzerklärung"
              }
            }
            li(classes = "nav-item") {
              a(classes = "nav-link active", href = "./") {
                attrs["data-bs-toggle"] = "modal"
                attrs["data-bs-target"] = "#impressum"

                +"Impressum"
              }
            }
          }
        }
      }
    }
  }
}


external interface NavbarProps : Props
