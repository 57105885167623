package it.neckar.react.common.toast


/**
 * Shows a success message
 */
fun notifySuccess(successMessage: String) {
  Toast.info(
    successMessage, options = ToastOptions(
      timeOut = 2000,
      positionClass = ToastPosition.BOTTOMCENTER
    )
  )
}

fun notifyError(title: String, errorMessage: String) {
  Toast.error(
    title = title,
    message = errorMessage,
    options = ToastOptions(
      timeOut = 7000,
      positionClass = ToastPosition.BOTTOMCENTER,
    ),
  )
}
