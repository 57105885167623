package redux.actions

import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import redux.RAction
import redux.WunschsolarAppState


/**
 * The redux reduce method
 */
fun reduceAction(state: WunschsolarAppState = WunschsolarAppState(), action: RAction?): WunschsolarAppState {
  if (action == null) {
    return state
  }

  logger.debug("Reducing store with: ${action::class.simpleName}")

  return when (action) {
    is StateAction -> {
      with(action) {
        return state.updateState()
      }
    }

    else -> {
      println("Unsupported action: $action")
      state
    }
  }
}


private val logger: Logger = LoggerFactory.getLogger("redux.actions.")
