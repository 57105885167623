package components.schnellbestellung

import it.neckar.open.collections.fastForEach
import de.wunschsolar.model.products.Bundle
import de.wunschsolar.model.products.ProductComponentConfiguration
import react.*
import react.dom.*

fun RBuilder.komplettpaketContentOverview(product: Bundle) {

  div {
    h6(classes = "col-12 mb-1") {
      +"Inhalt"
    }

    p(classes = "col-12 mb-3") {
      +"Das ${product.description} enthält alles, was Sie für Ihr PV-Projekt benötigen:"
    }
    ul(classes = "row gy-2 px-2 mx-2") {
      product.content.fastForEach { componentConfiguration: ProductComponentConfiguration ->
        div(classes = "col-12 col-md-6 gx-2 px-3") {
          li {
            +componentConfiguration.format()
            componentConfiguration.documentPath?.let {
              i(classes = "far fa-file-pdf ps-2 pe-1") {}
              a(href = it, classes = "link-dark", target = "_blank") {
                +"Datenblatt"
              }
            }
          }
        }
      }
    }
  }
}
