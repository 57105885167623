package services.http

import it.neckar.open.http.Url
import it.neckar.projects.common.Port
import it.neckar.projects.wunschsolar.WunschsolarPorts
import kotlinx.browser.document
import services.Server

/**
 * Calculates the urls for the services
 */
object WunschsolarUrlSupport {
  /**
   * The base uri of the document
   */
  val baseURI: Url.Absolute = Url.Absolute(
    document.baseURI
      .removeQueryParameters() //remove potential query parameters
      .substringBeforeLast("/") //remove file names "#" and other stuff that might have been added
  )

  /**
   * Returns the basename for the current configuration
   */
  @Deprecated("???")
  fun basename(): String {
    return when (Server.instance) {
      Server.File -> ""
      Server.Localhost -> ""
      Server.Spielwiese -> ""
      Server.Schaufenster -> ""
      Server.Production -> ""
    }
  }


  /**
   * URLs for the storage service
   */
  object StorageServiceUrls {
    /**
     * The port for storage service (dev connection)
     */
    val portForDev: Port = WunschsolarPorts.wunschsolarService

    object OrderUrls {

      val order: Url
        get() = createUrl("api/orders", portForDev)

      val discount: Url
        get() = createUrl("api/discount", portForDev)

      val feedback: Url
        get() = createUrl("api/feedback", portForDev)

    }
  }
}


/**
 * Removes the query parameters from a URL
 */
internal fun String.removeQueryParameters(): String {
  //Look for any chars that indicate parameters
  val endIndex = this.indexOfAny(charArrayOf('?', '&'))

  if (endIndex == -1) {
    //No parameters found
    return this
  }

  //Return only the base part - without parameters
  return substring(0, endIndex)
}

/**
 * Creates a URL for a service
 */
internal fun createUrl(
  /**
   * The relative path (is added to the base uri)
   */
  relativePath: String,
  /**
   * The port when connecting to the port during development
   */
  portForDev: Port,
): Url {
  return calculateBaseUrl(portForDev) + relativePath
}

/**
 * Returns the base URL - depending on the environment (dev or production)
 *
 * Returns an absolute URL with a trailing "/"
 */
internal fun calculateBaseUrl(portForDev: Port): Url.Absolute {
  return when (Server.instance) {
    Server.File -> Url.absolute("http://localhost:$portForDev/")
    Server.Localhost -> Url.absolute("http://localhost:$portForDev/")
    Server.Spielwiese -> WunschsolarUrlSupport.baseURI
    Server.Schaufenster -> WunschsolarUrlSupport.baseURI
    Server.Production -> WunschsolarUrlSupport.baseURI
  }
}
