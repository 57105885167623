import components.navbar
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.classes
import react.*
import react.dom.*
import styled.*


fun RBuilder.ueberUns(
): Unit = child(ueberUns) {
  attrs {
  }
}

val ueberUns: FC<UeberUnsProps> = fc("ueberUns") {

  useEffectOnce {
    window.scrollTo(0.0, 0.0)
  }


  navbar()

  div(classes = "pt-4") {
    styledDiv {
      attrs {
        classes = setOf("container", "p-4")
      }
      css {
        maxWidth = maxSiteWidth
      }

      h2 {
        +"Über uns"
      }
      p {
        +"Hier auf wunschsolar.de kann jeder einkaufen, der seine PV-Anlage selbst montieren möchte. Derzeit bieten wir Komplettpakete für unterschiedliche Dachgrößen bzw. gewünschte Leistungen an."
        br {}
        +"wunschsolar.de ist ein Projekt von uns, der Neckar IT GmbH."
      }


      //h4("mt-4") {
      //  +"Wer oder was ist Lizergy?"
      //}
      //p {
      //  +"Wir sind ein im Schwarzwald ansässiges Unternehmen, das seit 2013 PV-Anlagen vertreibt und montiert. Also im Grunde eine Photovoltaik-Firma. Allerdings bieten wir seit einigen Jahren auch unabhängig vom PV-Bereich Elektroinstallationen an."
      //  br {}
      //  +"Ob kleine Waldhütten, Geräteschuppen, Carports, Ein- und Mehrfamilienhäuser oder riesige Hallen – ob Neubau, Erweiterung oder Erneuerung – wir konnten uns in zahlreichen (Photovoltaik-)Projekten als zuverlässiger Ansprechpartner beweisen."
      //}
    }

    //styledImg(src = "img/about-us-page_lizergy.JPG") {
    //  css {
    //    classes.add("img-fluid")
    //  }
    //}

    styledDiv {
      attrs {
        classes = setOf("container", "p-4")
      }
      css {
        maxWidth = maxSiteWidth
      }

      h4("mt-4") {
        +"Die Motivation hinter wunschsolar.de"
      }
      p {
        +"Der eine oder andere wird sich jetzt denken \"Dann haben Sie doch genug zu tun und brauchen nicht auch noch einen Onlineshop.\" Hinter wunschsolar.de steckt aber mehr:"
      }

      h5("mt-4") {
        +"Umweltschutz natürlich"
      }
      p {
        +"Wir wollen die Erde und deren Ressourcen schonen und dem Klimawandel entgegentreten. Dafür müssen wir die Energiewende vorantreiben. Alleine schaffen wir das aber niemals. Nein, dafür braucht es vereinte Kräfte, also auch die Kräfte derjenigen, die gerne selbst aufs Dach steigen wollen. Das hängt auch mit dem nächsten Punkt zusammen:"
      }

      h5("mt-4") {
        +"Hohe Auslastung der Montagebetriebe im ganzen Land"
      }
      p {
        +"PV - Anlagen sind sehr nachgefragt. So nachgefragt, dass bei den Unternehmen die Montagekapazitäten schnell ausgeschöpft sind. Kunden haben also oft eine lange Wartezeit. Wer aber selbst montiert, der muss nicht auf das Montageteam warten, sondern kann loslegen, sobald er das Material hat."
      }
      /*
            h5("mt-4") {
              +"Nachfrage der elektrischen Inbetriebnahme"
            }
            p {
              +"Die elektrische Inbetriebnahme beziehungsweise der Anschluss ans Netz ist eines der größten Probleme der Personen, die ihre Anlage selbst montieren wollen. Dafür ist nämlich eine Elektrofachkraft vorgeschrieben. Doch die Suche nach einer solchen kann sich hinziehen, wenn man nicht zufällig jemanden kennt. Die entsprechenden Betriebe übernehmen nämlich beim Anschluss und mit ihrer Abnahme-Unterschrift eine große Verantwortung – schließlich kann der Privatmann auch gepfuscht haben. Die Frage nach einem Elektriker wird darum in Suchmaschinen und Foren häufig gestellt."
            }
            p {
              +"Aus all diesen Gründen dachten wir uns, dass wir einen Onlineshop einrichten wollen, bei dem man den Bausatz und den Elektriker bestellen kann. Auf Wunsch übernehmen wir auch z.B. den Papierkram, ganz nach dem Motto: Du montierst, wir erledigen den Rest."
            }
       */
    }
  }

  feedback {
    h4 {
      +"wunschsolar.de kann sich nur durch Dich weiterentwickeln"
    }

    div("mb-3") {
      p {
        +"Im Moment wollen wir noch sehen, wie unser Angebot angenommen wird. Darum verändert sich aktuell noch das eine oder andere. Beispielsweise sind unsere Pakete aktuell nur für Baden-Württemberg verfügbar. Wir planen aber, das zu ändern."
        br {}
        +"Auch weitere Verbesserungen sind geplant, wie zum Beispiel Montageanleitungen und individuelle Anlagenplanung."
        br {}
        +"Wir sind auf Dein Feedback angewiesen, um Dir das bestmögliche Angebot bieten zu können. Nutze dafür gerne das Formular unten. Wir freuen uns auf Deine Rückmeldung!"
      }
    }
  }

  wunschsolarFooter()
}


external interface UeberUnsProps : Props
