package components

import de.wunschsolar.model.products.AvailableBundles.complete12KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete12KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.complete3KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete3KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.complete4_5KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete4_5KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.complete6KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete6KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.complete6_75KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete6_75KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.complete8_25KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete8_25KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.complete9_9KWpBundle
import de.wunschsolar.model.products.AvailableBundles.complete9_9KWpWithoutBatteryBundle
import de.wunschsolar.model.products.AvailableBundles.wallboxABL1w1108emh1Bundle
import de.wunschsolar.model.products.AvailableBundles.wallboxSMAEVcharger22Bundle
import de.wunschsolar.model.products.Bundle
import feedback
import packageOverview
import react.*
import schnellbestellungKomplettpaket
import schnellbestellungWallbox
import store
import ueberUns
import wunschsolarFooter

/**
 * Contains the routes - entry points from routing
 */


/**
 * Main route - for the main application
 */
val MainRoute: FC<Props> = fc("MainRoute") {
  val appState = store.getState()

  navbar()

  packageOverview(appState)

  feedback()

  wunschsolarFooter()
}


val bundleRoutes: Map<Bundle, String> = mapOf(
  wallboxABL1w1108emh1Bundle to "ABL-eMH1-Basic-1W1108-Wallbox",
  wallboxSMAEVcharger22Bundle to "SMA-EV-Charger-22",

  complete3KWpBundle to "3-KWp-Paket",
  complete3KWpWithoutBatteryBundle to "3-KWp-Paket-ohne-Speicher",

  complete4_5KWpBundle to "4_5-KWp-Paket",
  complete4_5KWpWithoutBatteryBundle to "4_5-KWp-Paket-ohne-Speicher",

  complete6KWpBundle to "6-KWp-Paket",
  complete6KWpWithoutBatteryBundle to "6-KWp-Paket-ohne-Speicher",

  complete6_75KWpBundle to "6_75-KWp-Paket",
  complete6_75KWpWithoutBatteryBundle to "6_75-KWp-Paket-ohne-Speicher",

  complete8_25KWpBundle to "8_25-KWp-Paket",
  complete8_25KWpWithoutBatteryBundle to "8_25-KWp-Paket-ohne-Speicher",

  complete9_9KWpBundle to "9_9-KWp-Paket",
  complete9_9KWpWithoutBatteryBundle to "9_9-KWp-Paket-ohne-Speicher",

  complete12KWpBundle to "12-KWp-Paket",
  complete12KWpWithoutBatteryBundle to "12-KWp-Paket-ohne-Speicher",
)

val wallboxABL1w1108emh1BundleRoute: FC<Props> = fc("wallboxABL1w1108emh1BundleRoute") {
  schnellbestellungWallbox(store.getState(), wallboxABL1w1108emh1Bundle)
}
val wallboxSMAEVcharger22BundleRoute: FC<Props> = fc("wallboxSMAEVcharger22BundleRoute") {
  schnellbestellungWallbox(store.getState(), wallboxSMAEVcharger22Bundle)
}

val complete3KWpBundleRoute: FC<Props> = fc("complete3KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete3KWpBundle)
}
val complete3KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete3KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete3KWpWithoutBatteryBundle)
}

val complete4_5KWpBundleRoute: FC<Props> = fc("complete4_5KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete4_5KWpBundle)
}
val complete4_5KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete4_5KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete4_5KWpWithoutBatteryBundle)
}

val complete6KWpBundleRoute: FC<Props> = fc("complete6KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete6KWpBundle)
}
val complete6KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete6KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete6KWpWithoutBatteryBundle)
}

val complete6_75KWpBundleRoute: FC<Props> = fc("complete6_75KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete6_75KWpBundle)
}
val complete6_75KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete6_75KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete6_75KWpWithoutBatteryBundle)
}

val complete8_25KWpBundleRoute: FC<Props> = fc("complete8_25KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete8_25KWpBundle)
}
val complete8_25KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete8_25KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete8_25KWpWithoutBatteryBundle)
}

val complete9_9KWpBundleRoute: FC<Props> = fc("complete9_9KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete9_9KWpBundle)
}
val complete9_9KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete9_9KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete9_9KWpWithoutBatteryBundle)
}

val complete12KWpBundleRoute: FC<Props> = fc("complete12KWpBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete12KWpBundle)
}
val complete12KWpWithoutBatteryBundleRoute: FC<Props> = fc("complete12KWpWithoutBatteryBundleRoute") {
  schnellbestellungKomplettpaket(store.getState(), complete12KWpWithoutBatteryBundle)
}

val ueberUnsRoute: FC<Props> = fc("ueberUnsRoute") {
  ueberUns()
}
