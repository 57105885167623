package components.schnellbestellung

import components.schnellbestellung.forms.additionalNotesForm
import components.schnellbestellung.forms.discountForm
import components.schnellbestellung.forms.inputForms
import components.schnellbestellung.forms.inputs.CustomerInformationInputs
import components.schnellbestellung.forms.paymentForm
import de.wunschsolar.model.products.Bundle
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.id
import maxSiteWidth
import react.*
import react.dom.*
import redux.WunschsolarAppState
import styled.*


/**
 * A summary of all the order information
 * and the button to confirm the order
 */
fun RBuilder.schnellbestellungForm(
  appState: WunschsolarAppState,
  bundle: Bundle,
): Unit = child(schnellbestellungForm) {
  attrs {
    this.appState = appState
    this.bundle = bundle
  }
}

val schnellbestellungForm: FC<SchnellbestellungFormProps> = fc("schnellbestellungForm") { props ->

  val customerInformationInputs = CustomerInformationInputs(props.appState)

  if (true) {
    h3(classes = "col-12 mb-4") {
      +"Shop ist aktuell geschlossen! Bestellung leider zur Zeit nicht möglich"
    }
  } else {

    div(classes = "pt-5") {
      attrs {
        id = "bestellen"
      }

      div(classes = "bg-secondary py-4") {
        styledDiv {
          attrs {
            classes = setOf("container", "p-4")
          }
          css {
            maxWidth = maxSiteWidth
          }

          h2(classes = "mb-5") {
            +"Schnellbestellung"
          }


          /**
           * All the customer input forms
           */
          inputForms(customerInformationInputs)

          additionalNotesForm(customerInformationInputs)

          /**
           * The payment selection
           * Currently, there is only one payment option available, so it currently requires no user input
           */
          paymentForm(customerInformationInputs)

          discountForm(customerInformationInputs)
        }
      }
    }

    styledDiv {
      attrs {
        classes = setOf("container", "p-4", "mb-5")
      }
      css {
        maxWidth = maxSiteWidth
      }

      /**
       * A summary of all the order information
       * and the button to confirm the order
       */
      orderInformation(props.appState, customerInformationInputs, props.bundle)
    }
  }
}


external interface SchnellbestellungFormProps : Props {
  var appState: WunschsolarAppState
  var bundle: Bundle
}
